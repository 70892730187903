import { FC } from "react";
import useTabs from "../../../hooks/useTabs";
import useI18n from "../../../hooks/useTranslations";
import { HRailConfiguration } from "../../../http/types/configurations";
import Header from "../../ui/Header";
import Tabs from "../../ui/Tabs";

interface HRailProps {
  configuration: HRailConfiguration;
  isAdmin?: boolean;
}

const HRail: FC<HRailProps> = ({ configuration: data }: HRailProps) => {
  const i18n = useI18n();
  const controller = useTabs(1);

  return (
    <>
      <Header title={data.system.name} />
      <Tabs controller={controller} tabs={[{ title: i18n.translation.common.generalData }]} panels={[]} />
    </>
  );
};

export default HRail;
