import { FC } from "react";
import useTabs from "../../../hooks/useTabs";
import useI18n from "../../../hooks/useTranslations";
import { GuardConfiguration } from "../../../http/types/configurations";
import Header from "../../ui/Header";
import Tabs from "../../ui/Tabs";

interface GuardProps {
  configuration: GuardConfiguration;
  isAdmin?: boolean;
}

const Guard: FC<GuardProps> = ({ configuration: data }: GuardProps) => {
  const i18n = useI18n();
  const controller = useTabs(1);

  return (
    <>
      <Header title={data.system.name} />
      <Tabs controller={controller} tabs={[{ title: i18n.translation.common.generalData }]} panels={[]} />
    </>
  );
};

export default Guard;
