import useSWRImmutable from "swr/immutable";
import { useApi } from "../api";
import { CustomError } from "../errors";
import { VertigripSettings } from "../types/vertigrip/vertigripSettings";

export const useVertigripSettings = (signal: AbortSignal, configurationId?: string) => {
  const api = useApi();
  const url = `/settings/vertigrip${configurationId ? `?configuration_id=${configurationId}` : ""}`;
  return useSWRImmutable<VertigripSettings, CustomError, string>(url, (url) =>
    api.get<VertigripSettings>({ url, signal })
  );
};
