import { ButtonHTMLAttributes, FC, useState } from "react";
import useI18n from "../../hooks/useTranslations";
import { Glyph } from "../../types";
import SvgAdd from "../icons/Add";
import Button from "../ui/Button";
import ModalDialog from "../ui/ModalDialog";
import CreateProjectForm from "./CreateProjectForm";

interface CreateProjectButtonProps {
  onSubmit: (projectId: string) => void;
  glyph?: Glyph;
  buttonProps?: ButtonHTMLAttributes<HTMLButtonElement>;
}

const CreateProjectButton: FC<CreateProjectButtonProps> = ({
  onSubmit,
  glyph,
  buttonProps,
}: CreateProjectButtonProps) => {
  const i18n = useI18n();
  const [isModalOpen, setModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  return (
    <>
      <ModalDialog
        title={i18n.translation.projects.createNew}
        isOpen={isModalOpen}
        onClose={() => {
          setModalOpen(false);
          setIsSubmitting(false);
        }}
      >
        <CreateProjectForm
          closeModal={() => setModalOpen(false)}
          isSubmitting={isSubmitting}
          onSubmit={onSubmit}
          setIsSubmitting={setIsSubmitting}
        />
      </ModalDialog>
      <Button
        glyph={glyph ?? SvgAdd}
        buttonProps={{
          ...buttonProps,
          onClick: () => setModalOpen(true),
        }}
      >
        {i18n.translation.common.createNew}
      </Button>
    </>
  );
};

export default CreateProjectButton;
