import {
  Configuration,
  GuardConfiguration,
  HRailConfiguration,
  LadstepConfiguration,
  OverpassConfiguration,
  PatrolConfiguration,
  SafetyNetConfiguration,
  VertigripConfiguration,
} from "../../http/types/configurations";

export const isVertigrip = (data?: Configuration): data is VertigripConfiguration => {
  return data?.system?.type === "vertigrip";
};

export const isGuard = (data?: Configuration): data is GuardConfiguration => {
  return data?.system?.type === "guard";
};

export const isPatrol = (data?: Configuration): data is PatrolConfiguration => {
  return data?.system?.type === "patrol";
};

export const isHRail = (data?: Configuration): data is HRailConfiguration => {
  return data?.system?.type === "h-rail";
};

export const isSafetyNet = (data?: Configuration): data is SafetyNetConfiguration => {
  return data?.system?.type === "safety-net";
};

export const isLadstep = (data?: Configuration): data is LadstepConfiguration => {
  return data?.system?.type === "ladstep";
};

export const isOverpass = (data?: Configuration): data is OverpassConfiguration => {
  return data?.system?.type === "overpass";
};
