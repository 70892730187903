import classNames from "classnames";

import { FC, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useI18n from "../../hooks/useTranslations";
import { useItemsApi } from "../../http/useItems";
import SvgNoImage from "../icons/SvgNoImage";
import Button from "../ui/Button";
import Header from "../ui/Header";
import Icon from "../ui/Icon";
import ScrollContent from "../ui/ScrollContent";
import WhiteSpacePreWrapText from "../ui/WhiteSpacePreWrapText";
import styles from "./Item.module.css";

const Item: FC = () => {
  const i18n = useI18n();
  const { id } = useParams<{ id: string }>();
  const aborter = useRef(new AbortController());
  const itemsApi = useItemsApi();
  const navigate = useNavigate();

  const { error, data } = itemsApi.getItem(id!, aborter.current.signal);

  return (
    <>
      <Header
        title={data?.code ?? i18n.translation.common.loading}
        // TODO add "Edit item" button if the user has admin roles (or edit-item-permission)
        error={error}
        path={[{ display: i18n.translation.items.all, url: "/items" }, { display: data?.code ?? "" }]}
      />

      <ScrollContent padding>
        <div className={styles.container}>
          <div className={styles.imgContainer}>
            <div className={classNames(styles.img, { [styles.background]: !data?.image })}>
              {data?.image ? <img src={import.meta.env.VITE_BUCKET_URL + data.image} /> : <Icon glyph={SvgNoImage} />}
            </div>

            <Button
              buttonProps={{
                onClick: () => navigate(-1),
              }}
            >
              {i18n.translation.common.back}
            </Button>
          </div>
          <div>
            {!!data?.name && <div>{data.name}</div>}
            <WhiteSpacePreWrapText>{data?.description}</WhiteSpacePreWrapText>
          </div>
        </div>
      </ScrollContent>
    </>
  );
};

export default Item;
