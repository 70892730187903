import { FC, useContext, useRef } from "react";
import { useParams } from "react-router-dom";
import NotificationDispatch, { showErrorNotification } from "../../context/notificationContext";
import useTabs from "../../hooks/useTabs";
import useI18n from "../../hooks/useTranslations";
import { useProjectsApi } from "../../http/useProjects";
import ProjectConfigurations from "../configurations/ProjectConfigurations";
import Header from "../ui/Header";
import Tabs from "../ui/Tabs";
import ProjectDetails, { ProjectDetailsFormBody } from "./ProjectDetails";
import ProjectDocuments from "./ProjectDocuments";

interface ProjectProps {
  isAdmin?: boolean;
}

const Project: FC<ProjectProps> = ({ isAdmin }: ProjectProps) => {
  const i18n = useI18n();
  const dispatch = useContext(NotificationDispatch);
  const projectsApi = useProjectsApi();

  const tabs = [
    { title: i18n.translation.common.details },
    { title: i18n.translation.configurations.plural },
    { title: i18n.translation.documents.plural },
  ];

  const controller = useTabs(tabs.length);

  const { id } = useParams<{ id: string }>();

  const aborter = useRef(new AbortController());

  const { error, data, isLoading } = projectsApi.useProject(id!, aborter.current.signal);

  const submit = (body: ProjectDetailsFormBody) =>
    projectsApi
      .updateProject(id!, {
        name: body.name!,
        address: body.address,
        coordinates: body.coordinates,
        description: body.description,
        language: body.language?.value!,
        status: body.status?.value!,
        company: body.company,
        contactPerson: body.contactPerson,
      })
      .catch((err) => dispatch(showErrorNotification(i18n, err)));

  return (
    <>
      <Header
        title={i18n.translation.projects.singular}
        error={error}
        path={[
          {
            display: isAdmin ? i18n.translation.projects.all : i18n.translation.projects.my,
            url: `${isAdmin ? "/admin" : ""}/projects`,
          },
          { display: data?.name! },
        ]}
        buttons={
          [
            // <Button
            //   glyph={SvgPrint}
            //   buttonProps={{
            //     disabled: !!error,
            //     onClick: () => alert("TODO"),
            //   }}
            // >
            //   {i18n.translation.common.print}
            // </Button>,
          ]
        }
      />
      <Tabs
        controller={controller}
        tabs={tabs}
        panels={[
          <ProjectDetails
            project={data}
            onSubmit={submit}
            disabled={!!error}
            isLoading={isLoading}
            isAdmin={isAdmin}
          />,
          <ProjectConfigurations projectId={id!} isAdmin={isAdmin} disabled={!!error} />,
          <ProjectDocuments />,
        ]}
      />
    </>
  );
};

export default Project;
