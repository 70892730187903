import { FC, useContext, useRef } from "react";
import { Link } from "react-router-dom";
import NotificationDispatch, { showErrorNotification } from "../../../context/notificationContext";
import useInitializer from "../../../hooks/useInitializer";
import useI18n from "../../../hooks/useTranslations";
import { ListItemWithQuantity } from "../../../http/types/items";
import { useItemsApi } from "../../../http/useItems";
import SvgExcel from "../../icons/Excel";
import Button from "../../ui/Button";
import ScrollContent from "../../ui/ScrollContent";
import SubmitBar from "../../ui/SubmitBar";
import Table from "../../ui/Table";
import styles from "./VertigripItemsList.module.css";

interface VertigripItemsListProps {
  configurationId: string;
  isAdmin?: boolean;
  back: () => void;
}

const VertigripItemsList: FC<VertigripItemsListProps> = ({
  configurationId,
  isAdmin,
  back,
}: VertigripItemsListProps) => {
  const i18n = useI18n();
  const dispatch = useContext(NotificationDispatch);
  const aborter = useRef(new AbortController());
  const itemsApi = useItemsApi();
  const { data, error } = itemsApi.getConfigurationItemsList(configurationId, aborter.current.signal);

  const isInitialized = useInitializer(data || error);

  if (!data && !error && !isInitialized) {
    return;
  }

  return (
    <ScrollContent direction="row">
      <div className={styles.form}>
        <Table<ListItemWithQuantity>
          head={
            <tr>
              <th align="left">{i18n.translation.common.code}</th>
              <th align="left">{i18n.translation.common.description}</th>
              <th align="right">{i18n.translation.common.qty}</th>
              <th align="right">{i18n.translation.common.minOrderQuantity}</th>
            </tr>
          }
          items={data}
          renderRow={(item) => (
            <>
              <td>
                <Link to={`${isAdmin ? "/admin" : ""}/items/${item.id}`}>{item.code}</Link>
              </td>
              <td>{item.description}</td>
              <td align="right">{item.quantity}</td>
              <td align="right">{item.minOrderQuantity}</td>
            </>
          )}
          error={!!error}
        ></Table>
        <SubmitBar>
          <Button
            buttonProps={{
              disabled: !data || !!error,
              onClick: back,
            }}
            type="secondary"
          >
            {i18n.translation.common.back}
          </Button>
          <Button
            glyph={SvgExcel}
            buttonProps={{
              disabled: !data || !!error,
              onClick: () =>
                itemsApi
                  .downloadEServiceExportConfigurationItemsList(configurationId)
                  .catch((err) => dispatch(showErrorNotification(i18n, err))),
            }}
          >
            {i18n.translation.common.excel}
          </Button>
        </SubmitBar>
      </div>
    </ScrollContent>
  );
};

export default VertigripItemsList;
