import { FC, useState } from "react";
import { Link } from "react-router-dom";
import useI18n from "../../hooks/useTranslations";
import styles from "./CookiesBanner.module.css";

const ESSENTIAL_COOKIES = "bsafe_essential_cookies_accepted";

const CookiesBanner: FC = () => {
  const i18n = useI18n();

  const setCookie = (name: string, days: number = 365) => {
    const expires = new Date();
    expires.setTime(expires.getTime() + days * 24 * 60 * 60 * 1000);
    document.cookie = `${name}=true; expires=${expires.toUTCString()}; path=/`;
  };

  const getCookie = (name: string): boolean => {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) {
      return parts.pop()?.split(";").shift() === "true";
    }
    return false;
  };

  const [showBanner, setShowBanner] = useState(!!getCookie(ESSENTIAL_COOKIES));

  const accept = () => {
    setCookie(ESSENTIAL_COOKIES);
    setShowBanner(true);
  };

  const getWebsiteLanguage = (language: string): string => {
    if (["it", "de", "fr", "es", "pt", "cn", "pl"].includes(language.toLowerCase())) {
      return language.toLowerCase();
    }
    return "com";
  };

  return (
    <>
      {!showBanner && (
        <div className={styles.cookiesBanner}>
          <div dangerouslySetInnerHTML={{ __html: i18n.translation.common.cookiesText }}></div>
          <div>
            <Link
              to={`https://www.rothoblaas.${getWebsiteLanguage(i18n.translation.languageCode)}/cookie-policy`}
              target="_blank"
            >
              {i18n.translation.common.moreInformation} »
            </Link>
          </div>
          <div>
            <button onClick={accept}>{i18n.translation.common.okIreadIt}</button>
          </div>
        </div>
      )}
    </>
  );
};

export default CookiesBanner;
