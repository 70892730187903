import { FC, useContext, useRef } from "react";
import NotificationDispatch, { showErrorNotification } from "../../context/notificationContext";
import useOnClickOutside from "../../hooks/useOnClickOutside";
import useToggle from "../../hooks/useToggle";
import useI18n from "../../hooks/useTranslations";
import { ProjectStatus, SearchProject } from "../../http/types/projects";
import { useProjectsApi } from "../../http/useProjects";
import SvgEdit from "../icons/Edit";
import Icon from "../ui/Icon";
import styles from "./EditProjectStatus.module.css";

interface EditProjectStatusProps {
  project: SearchProject;
  stati: ProjectStatus[];
  onUpdate: (status: string) => void;
}

const EditProjectStatus: FC<EditProjectStatusProps> = ({ project, stati, onUpdate }) => {
  const i18n = useI18n();
  const ref = useRef<HTMLDivElement>(null);
  const menu = useToggle();
  const dispatch = useContext(NotificationDispatch);
  const projectsApi = useProjectsApi();

  useOnClickOutside(ref, menu.close);

  const updateStatus = (status: string) => {
    projectsApi
      .updateProjectStatus(project.id, status)
      .then(() => {
        onUpdate(status);
        menu.close();
      })
      .catch((err) => dispatch(showErrorNotification(i18n, err)));
  };

  return (
    <div ref={ref} className={styles.projectStatusWrapper}>
      <div onClick={menu.toggle} className={styles.projectStatus}>
        {stati.find((x) => x.value === project.status)?.display}
        <span className={styles.statusEdit}>
          <Icon glyph={SvgEdit} />
        </span>
      </div>

      {menu.isOpen && (
        <div className={styles.projectStatusMenu}>
          <ul className={styles.projectStatusUl}>
            {stati.map((x) => (
              <li key={x.value} onClick={() => updateStatus(x.value)}>
                {x.display}
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default EditProjectStatus;
