import { useCallback } from "react";
import { mutate } from "swr";
import useSWRImmutable from "swr/immutable";
import useSWRMutation from "swr/mutation";
import { Paginated, PaginationQuery } from "../types";
import { buildUrl, mutateCache, useApi } from "./api";
import { CustomError } from "./errors";
import { FullClient, SearchClient, UpsertClientBody } from "./types/clients";

export const useClientsApi = () => {
  const api = useApi();

  // Get a single client
  const getClient = (id: string, signal: AbortSignal) => {
    return useSWRMutation<FullClient, CustomError, string>(`/admin/clients/${id}`, (url) =>
      api.get<FullClient>({ url, signal })
    );
  };

  // Get a paginated list of clients
  const useClients = (query: PaginationQuery, signal: AbortSignal) => {
    const url = buildUrl("/admin/clients", query);
    return useSWRImmutable<Paginated<SearchClient>, CustomError, string>(url, (url) =>
      api.get<Paginated<SearchClient>>({ url, signal })
    );
  };

  // Insert a new client
  const insertClient = useCallback(async (body: UpsertClientBody): Promise<SearchClient> => {
    const url = `/admin/clients`;
    const item = await api.post<SearchClient, UpsertClientBody>(url, body);
    mutateCache(["/admin/clients"]);
    return item;
  }, []);

  // Update an existing client
  const updateClient = useCallback(async (itemId: string, body: UpsertClientBody): Promise<void> => {
    const url = `/admin/clients/${itemId}`;
    const item = api.put<UpsertClientBody>(url, body);
    await mutate(url, item);
    mutateCache(["/admin/clients"], url);
  }, []);

  // Delete a client
  const deleteClient = useCallback(async (clientId: string): Promise<void> => {
    await api.del(`/admin/clients/${clientId}`).then(() => {
      mutateCache(["/admin/clients", `/admin/clients/${clientId}`]);
    });
  }, []);

  return {
    getClient,
    useClients,
    insertClient,
    updateClient,
    deleteClient,
  };
};
