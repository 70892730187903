import { useCallback } from "react";
import useSWRImmutable from "swr/immutable";
import { Paginated, PaginationQuery } from "../types";
import { buildUrl, mutateCache, useApi } from "./api";
import { CustomError } from "./errors";
import {
  Configuration,
  InsertConfigurationBody,
  SearchAdminConfiguration,
  SearchConfiguration,
  UpdateConfigurationBody,
} from "./types/configurations";

export const useConfigurationsApi = () => {
  const api = useApi();

  const getProjectConfigurations = useCallback(
    (projectId: string, query: PaginationQuery, signal: AbortSignal) => {
      const url = buildUrl(`/projects/${projectId}/configurations`, query);
      return useSWRImmutable<Paginated<SearchConfiguration>, CustomError, string>(url, (url) =>
        api.get<Paginated<SearchConfiguration>>({ url, signal })
      );
    },
    [api]
  );

  const getConfiguration = useCallback(
    (configurationId: string, signal: AbortSignal) => {
      const url = `/configurations/${configurationId}`;
      return useSWRImmutable<Configuration, CustomError, string>(url, (url) => api.get<Configuration>({ url, signal }));
    },
    [api]
  );

  const getConfigurations = useCallback(
    (query: PaginationQuery, signal: AbortSignal) => {
      const url = buildUrl(`/configurations`, query);
      return useSWRImmutable<Paginated<SearchConfiguration>, CustomError, string>(url, (url) =>
        api.get<Paginated<SearchConfiguration>>({ url, signal })
      );
    },
    [api]
  );

  const getAdminConfigurations = useCallback(
    (query: PaginationQuery, signal: AbortSignal) => {
      const url = buildUrl(`/admin/configurations`, query);
      return useSWRImmutable<Paginated<SearchAdminConfiguration>, CustomError, string>(url, (url) =>
        api.get<Paginated<SearchAdminConfiguration>>({ url, signal })
      );
    },
    [api]
  );

  const getAdminConfiguration = useCallback(
    (configurationId: string, signal: AbortSignal) => {
      const url = `/admin/configurations/${configurationId}`;
      return useSWRImmutable<Configuration, CustomError, string>(url, (url) => api.get<Configuration>({ url, signal }));
    },
    [api]
  );

  const insertConfiguration = useCallback(
    async (body: InsertConfigurationBody): Promise<{ id: string }> => {
      const id = await api.post<{ id: string }, InsertConfigurationBody>(`/configurations`, body);
      mutateCache([
        "/projects",
        "/configurations",
        "/admin/projects",
        "/admin/configurations",
        `/projects/${body.projectId}/configurations`,
      ]);
      return id;
    },
    [api]
  );

  // the mutate parameter is needed when the user navigates from the items list to an item and come back.
  // if we do not mutate, an old version is showed, data is missing in the UI.
  // TODO we could add the optimistic data in order to avoid the mutate/reload
  const updateConfiguration = useCallback(
    async (
      projectId: string,
      configurationId: string,
      body: UpdateConfigurationBody,
      mutate?: boolean
    ): Promise<void> => {
      const url = `/configurations/${configurationId}`;
      api.put<UpdateConfigurationBody>(url, body);
      mutateCache(
        [
          "/projects",
          "/admin/projects",
          "/admin/configurations",
          `/projects/${projectId}/configurations`,
          `/configurations/${configurationId}/items`,
          mutate ? url : "",
        ],
        mutate ? undefined : url
      );
    },
    [api]
  );

  const deleteConfiguration = useCallback(
    async (projectId: string, configurationId: string): Promise<void> => {
      await api.del(`/configurations/${configurationId}`).then(() => {
        mutateCache([
          "/projects",
          `/projects/${projectId}/configurations`,
          "/configurations",
          `/configurations/${configurationId}`,
          "/admin/projects",
          "/admin/configurations",
        ]);
      });
    },
    [api]
  );

  const duplicateConfiguration = useCallback(
    async (projectId: string, configurationId: string, body: { name: string }): Promise<void> => {
      await api.post(`/configurations/${configurationId}/duplicate`, body).then(() => {
        mutateCache([
          "/projects",
          `/projects/${projectId}/configurations`,
          "/configurations",
          `/configurations/${configurationId}`,
          "/admin/projects",
          "/admin/configurations",
        ]);
      });
    },
    [api]
  );

  return {
    getProjectConfigurations,
    getConfiguration,
    getConfigurations,
    getAdminConfigurations,
    getAdminConfiguration,
    insertConfiguration,
    updateConfiguration,
    deleteConfiguration,
    duplicateConfiguration,
  };
};
