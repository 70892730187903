import { FC, PropsWithChildren } from "react";
import styles from "./WhiteSpacePreWrapText.module.css";

type WhiteSpacePreWrapTextProps = PropsWithChildren<{
  children?: string;
}>;

const WhiteSpacePreWrapText: FC<WhiteSpacePreWrapTextProps> = ({ children }) => (
  <p className={styles.preWrap}>{children}</p>
);

export default WhiteSpacePreWrapText;
