import { FC, useContext, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import ReactSelect from "react-select";
import NotificationDispatch, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import { useAuth } from "../../context/useAuthContext";
import useI18n from "../../hooks/useTranslations";
import { UpdateUserBody, User } from "../../http/types/users";
import { useRolesApi } from "../../http/useRoles";
import { useUsersApi } from "../../http/useUsers";
import { getUILanguages, Language } from "../../types";
import Form from "../ui/Form";
import FormField from "../ui/FormField";
import FormFieldsContainer from "../ui/FormFieldsContainer";
import Header from "../ui/Header";
import Input from "../ui/Input";
import MultiCheckbox from "../ui/MultiCheckbox";
import reactSelectStyles from "../ui/ReactSelectUtils";
import ScrollContent from "../ui/ScrollContent";
import Switch from "../ui/Switch";

export const AdminUser: FC = () => {
  const i18n = useI18n();
  const { id } = useParams<{ id: string }>();
  const aborter = useRef(new AbortController());
  const usersApi = useUsersApi();
  const { permissions } = useAuth();

  const { error, data, isMutating, trigger } = usersApi.getAdminUser(id!, aborter.current.signal);

  useEffect(() => {
    id && trigger();
  }, [id]);

  return (
    <>
      <Header
        title={i18n.translation.users.plural}
        path={[
          { display: i18n.translation.users.all, url: "/admin/users" },
          { display: !!error ? "" : data?.name ?? "" },
        ]}
        error={error}
      />

      <UserForm
        user={!error ? data : undefined}
        disabled={(id && (isMutating || !permissions.admin.canManageUsers)) || !!error}
      />
    </>
  );
};

interface UserProps {
  user?: User;
  disabled?: boolean;
}

interface UserFormBody {
  name: string | null;
  email: string | null;
  isActive: boolean;
  language: Language | null;
  roles: string[];
  permissions: string[];
}

const UserForm: FC<UserProps> = ({ user, disabled }: UserProps) => {
  const auth = useAuth();
  const i18n = useI18n();
  const dispatch = useContext(NotificationDispatch);
  // const navigate = useNavigate();
  const rolesApi = useRolesApi();
  const usersApi = useUsersApi();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const languages = getUILanguages(i18n.translation);

  const defaultValues: UserFormBody = {
    name: user?.name ?? null,
    email: user?.email ?? null,
    isActive: user?.isActive ?? false,
    language: languages.find((x) => x.value == user?.language) ?? null,
    roles: user?.roles ?? [],
    permissions: user?.permissions ?? [],
  };

  const rolesSwr = rolesApi.getAdminRoles();

  useEffect(() => {
    auth.permissions.admin.canManageUsers && rolesSwr.trigger();
  }, [auth.permissions.admin.canManageUsers]);

  useEffect(() => {
    rolesSwr.error && dispatch(showErrorNotification(i18n, rolesSwr.error));
  }, [rolesSwr.error]);

  const allRoles =
    rolesSwr.data?.map((x) => ({
      id: x.value,
      title: x.value,
      permissions: x.permissions,
    })) ?? [];

  const ps = rolesSwr.data?.reduce((rv: Map<string, { id: string; title: string }>, v) => {
    for (const p of v.permissions) {
      !rv.has(p) && rv.set(p, { id: p, title: p });
    }
    return rv;
  }, new Map());
  const allPermissions = ps ? Array.from(ps.values()) : [];

  const onSubmit = () => (formData: UserFormBody) => {
    setIsSubmitting(true);
    const body: UpdateUserBody = {
      roles: formData.roles ?? [],
      permissions: formData.permissions ?? [],
    };

    if (user?.id) {
      usersApi
        .updateUser(user.id, body)
        .then(() => dispatch(showSuccessNotification(i18n.translation)))
        .catch((err) => dispatch(showErrorNotification(i18n, err)))
        .finally(() => setIsSubmitting(false));
    } else {
      // usersApi
      //   .insertUser(body)
      //   .then((res) => navigate(`/admin/users/${res.id}`, { replace: true }))
      //   .catch((err) => dispatch(showErrorNotification(i18n, err)))
      //   .finally(() => setIsSubmitting(false));
    }
  };

  return (
    <ScrollContent>
      <Form<UserFormBody>
        submitText={i18n.translation.common.save}
        disabled={disabled || isSubmitting}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
      >
        {({ register, formState: { errors }, setValue, reset, watch, control }) => {
          useEffect(() => {
            reset(defaultValues);
          }, [user]);

          const roles = watch("roles");
          const permissions = watch("permissions");

          return (
            <FormFieldsContainer>
              <FormField label={i18n.translation.common.name} error={errors.name}>
                {({ labelId, isOptional, isInvalid }) => (
                  <Input
                    id={labelId}
                    isInvalid={isInvalid}
                    disabled={disabled}
                    {...register("name", {
                      required: !isOptional,
                      minLength: 1,
                    })}
                  />
                )}
              </FormField>
              <FormField label={i18n.translation.common.email} error={errors.email}>
                {({ labelId, isOptional, isInvalid }) => (
                  <Input
                    id={labelId}
                    isInvalid={isInvalid}
                    disabled={disabled}
                    {...register("email", {
                      required: !isOptional,
                      minLength: 1,
                    })}
                  />
                )}
              </FormField>
              <Controller
                name="language"
                control={control}
                rules={{ required: true }}
                defaultValue={defaultValues.language}
                render={({ field, fieldState }) => (
                  <FormField label={i18n.translation.common.languages.singular} error={fieldState.error}>
                    {({ labelId }) => (
                      <ReactSelect
                        {...field}
                        inputId={labelId}
                        options={languages}
                        isDisabled={disabled}
                        getOptionLabel={(x) => x.display}
                        styles={reactSelectStyles<Language>()}
                        onChange={(value) => setValue("language", value ?? null)}
                      />
                    )}
                  </FormField>
                )}
              />
              <FormField label={i18n.translation.common.active}>
                {({ labelId }) => (
                  <Switch
                    id={labelId}
                    checked={watch("isActive")}
                    onChange={(checked) => setValue("isActive", checked)}
                    disabled={disabled || user?.id === auth.identity?.id}
                  />
                )}
              </FormField>
              <FormField label={i18n.translation.roles.plural}>
                <MultiCheckbox
                  values={allRoles.map((x) => ({
                    ...x,
                    selected: roles.includes(x.id),
                  }))}
                  disabled={disabled || user?.id === auth.identity?.id}
                  onUpdate={(id, checked) => {
                    setValue("roles", checked ? [...roles, id] : roles.filter((x) => x !== id));
                  }}
                />
              </FormField>
              <FormField label={i18n.translation.permissions.plural}>
                <MultiCheckbox
                  values={(() => {
                    const ps = allPermissions.map((x) => {
                      const is = !!allRoles
                        .filter((r) => roles.includes(r.id))
                        .flatMap((r) => r.permissions)
                        .filter((p) => p === x.id).length;

                      return {
                        ...x,
                        selected: permissions.includes(x.id) || is,
                        disabled: is,
                      };
                    });
                    const oneSel = !!ps.find((x) => x.selected);
                    return ps.map((x) => ({
                      ...x,
                      selected: x.selected || (x.title === "access" && oneSel),
                      disabled: x.disabled || (x.title === "access" && oneSel),
                    }));
                  })()}
                  disabled={disabled || user?.id === auth.identity?.id}
                  onUpdate={(id, checked) => {
                    setValue("permissions", checked ? [...permissions, id] : permissions.filter((x) => x !== id));
                  }}
                />
              </FormField>
            </FormFieldsContainer>
          );
        }}
      </Form>
    </ScrollContent>
  );
};
