import { Glyph } from "../../types";

const SvgViewList: Glyph = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" {...props}>
      <path d="M336-264h456v-96H336v96ZM168-600h96v-96h-96v96Zm0 168h96v-96h-96v96Zm0 168h96v-96h-96v96Zm168-168h456v-96H336v96Zm0-168h456v-96H336v96ZM168-192q-29.7 0-50.85-21.16Q96-234.32 96-264.04v-432.24Q96-726 117.15-747T168-768h624q29.7 0 50.85 21.16Q864-725.68 864-695.96v432.24Q864-234 842.85-213T792-192H168Z" />
    </svg>
  );
};

export default SvgViewList;
