import { FC, useContext, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NotificationDispatch, { showErrorNotification } from "../../context/notificationContext";
import { useDebounce } from "../../hooks/useDebounce";
import useInitializer from "../../hooks/useInitializer";
import useListFilters from "../../hooks/useListFilters";
import useI18n from "../../hooks/useTranslations";
import { SearchAdminSystem } from "../../http/types/systems";
import { useSystemsApi } from "../../http/useSystems";
import { PaginationQuery } from "../../types";
import { formatBoolean } from "../../utils";
import SvgAdd from "../icons/Add";
import Button from "../ui/Button";
import Header from "../ui/Header";
import ModalDialog from "../ui/ModalDialog";
import ScrollContent from "../ui/ScrollContent";
import Table from "../ui/Table";

const AdminSystems: FC = () => {
  const i18n = useI18n();
  const navigate = useNavigate();
  const filters = useListFilters({ isSearchable: true });
  const dispatch = useContext(NotificationDispatch);
  const aborter = useRef(new AbortController());
  const [itemToDelete, setItemToDelete] = useState<SearchAdminSystem>();
  const systemsApi = useSystemsApi();

  const debouncedValue = useDebounce(filters.state.q);

  const query: PaginationQuery = {
    ...filters.state,
    q: debouncedValue,
  };

  const { data, error } = systemsApi.getAdminSystems(query, aborter.current.signal);

  const isInitialized = useInitializer(data || error);

  if (!data && !error && !isInitialized) {
    return;
  }

  return (
    <>
      <Header
        title={i18n.translation.systems.plural}
        error={error}
        path={{ display: i18n.translation.systems.all }}
        buttons={
          <Button
            glyph={SvgAdd}
            buttonProps={{
              onClick: () => navigate(`/admin/systems/new`),
            }}
          >
            {i18n.translation.common.createNew}
          </Button>
        }
      />
      <ScrollContent padding>
        <Table<SearchAdminSystem>
          filters={filters}
          isTall={true}
          searchOptions={{
            placeholder: i18n.translation.systems.search,
            onChange: () => {
              aborter.current.abort();
              aborter.current = new AbortController();
            },
          }}
          head={
            <tr>
              <th align="left">{i18n.translation.common.name}</th>
              <th align="left">{i18n.translation.common.visible}</th>
              <th align="left">{i18n.translation.common.order}</th>
              <th></th>
            </tr>
          }
          items={data?.items}
          renderRow={(item) => (
            <>
              <td>
                <Link to={`/admin/systems/${item.id}`}>{item.name}</Link>
              </td>
              <td>{formatBoolean(i18n.translation, item.isVisible)}</td>
              <td>{item.order}</td>
            </>
          )}
          rowActions={[
            { title: i18n.translation.common.edit, onClick: (item) => navigate(`/admin/systems/${item.id}`) },
            {
              title: i18n.translation.common.settings,
              onClick: (item) => navigate(`/admin/systems/${item.id}/settings`),
              isDisabled: (item) => !item.type,
            },
            { title: i18n.translation.common.delete, onClick: (item) => setItemToDelete(item) },
          ]}
          total={data?.total}
          error={!!error}
        />
        <ModalDialog
          isOpen={!!itemToDelete}
          onClose={() => setItemToDelete(undefined)}
          title={i18n.translation.systems.delete}
          actions={[
            {
              title: i18n.translation.common.cancel,
              onClick: () => setItemToDelete(undefined),
            },
            {
              title: i18n.translation.common.yes,
              onClick: () => {
                itemToDelete &&
                  systemsApi
                    .deleteAdminSystem(itemToDelete.id)
                    .then(() => setItemToDelete(undefined))
                    .catch((err) => dispatch(showErrorNotification(i18n, err)));
              },
            },
          ]}
        >
          {i18n.translation.systems.questions.delete}
        </ModalDialog>
      </ScrollContent>
    </>
  );
};

export default AdminSystems;
