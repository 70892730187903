import { FC, useContext, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import ReactSelect from "react-select";
import NotificationDispatch, { showErrorNotification } from "../../context/notificationContext";
import useI18n from "../../hooks/useTranslations";
import { InsertConfigurationBody } from "../../http/types/configurations";
import { SearchProject } from "../../http/types/projects";
import { System } from "../../http/types/systems";
import { useConfigurationsApi } from "../../http/useConfigurations";
import { useProjectsApi } from "../../http/useProjects";
import { useSystemsApi } from "../../http/useSystems";
import { getAllLanguages, Language } from "../../types";
import SvgAdd from "../icons/Add";
import CreateProjectForm from "../projects/CreateProjectForm";
import Button from "../ui/Button";
import Form from "../ui/Form";
import FormField from "../ui/FormField";
import FormFieldsContainer from "../ui/FormFieldsContainer";
import Input from "../ui/Input";
import LoadingSpinner from "../ui/LoadingSpinner";
import ModalDialog from "../ui/ModalDialog";
import reactSelectStyles from "../ui/ReactSelectUtils";
import Textarea from "../ui/Textarea";
import styles from "./CreateConfigurationButton.module.css";

interface CreateConfigurationForm {
  system: System | null;
  project: SearchProject | null;
  name: string | null;
  description: string | null;
  language: Language | null;
}

type CreateConfigurationButtonProps = {
  title: string;
  disabled?: boolean;
  isAdmin?: boolean;
} & (
  | {
      type: "project";
      projectId: string;
    }
  | {
      type: "system";
      systemId: string;
    }
  | {
      type: "configurations";
    }
);

const CreateConfigurationButton: FC<CreateConfigurationButtonProps> = (props: CreateConfigurationButtonProps) => {
  const i18n = useI18n();
  const [isModalOpen, setModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useContext(NotificationDispatch);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [page, setPage] = useState<"configuration" | "project">("configuration");
  const systemApi = useSystemsApi();
  const projectApi = useProjectsApi();
  const configurationsApi = useConfigurationsApi();

  const languages = getAllLanguages(i18n.translation);

  const aborter = useRef(new AbortController());

  const initialSystemId = props.type == "system" ? props.systemId : undefined;
  const {
    trigger: triggerSystems,
    data: systems,
    error: isErrorSystems,
    isMutating: isLoadingSystems,
  } = systemApi.getSystems(aborter.current.signal);

  const initialProjectId = props.type == "project" ? props.projectId : undefined;
  const { trigger: triggerProject, data: project } = projectApi.getProject(initialProjectId!, aborter.current.signal);
  const {
    trigger: triggerProjects,
    data: myProjects,
    isMutating: isLoadingProjects,
  } = projectApi.getMyProjects(aborter.current.signal);

  // useEffect(() => {
  //   initialProjectId && triggerProject();
  // }, [initialProjectId]);

  const [newProjectId, setNewProjectId] = useState<string>();

  const onSubmit = (formData: CreateConfigurationForm) => {
    setIsSubmitting(true);
    const body: InsertConfigurationBody = {
      name: formData.name ?? "",
      description: formData.description!,
      projectId: initialProjectId ?? formData.project?.id!,
      systemId: initialSystemId ?? formData.system?.id!,
      language: formData.language?.value!,
    };

    configurationsApi
      .insertConfiguration(body)
      .then((res) =>
        navigate(
          `${props.isAdmin ? "/admin" : ""}${
            props.type !== "configurations" ? `/projects/${body.projectId}` : ""
          }/configurations/${res.id}`
        )
      )
      .catch((err) => {
        dispatch(showErrorNotification(i18n, err));
        setIsSubmitting(false);
      });
  };

  const defaultValues: CreateConfigurationForm = {
    system: systems?.find((x) => x.id == initialSystemId) ?? null,
    project: myProjects?.find((x) => x.id == initialProjectId) ?? null,
    name: i18n.translation.configurations.new,
    description: null,
    language: null,
  };

  return (
    <>
      <ModalDialog
        title={
          page == "configuration" ? i18n.translation.configurations.createNew : i18n.translation.projects.createNew
        }
        isOpen={isModalOpen}
        onClose={() => {
          if (page == "configuration") {
            setModalOpen(false);
            setNewProjectId(undefined);
          } else {
            setPage("configuration");
          }
        }}
      >
        {page == "configuration" && (
          <Form<CreateConfigurationForm>
            defaultValues={defaultValues}
            disabled={isSubmitting}
            submitText={i18n.translation.common.create}
            secondaryButton={
              <>
                {isSubmitting && <LoadingSpinner delayed={0} size="small" />}
                <Button
                  buttonProps={{
                    disabled: isSubmitting,
                    onClick: () => {
                      setModalOpen(false);
                      setNewProjectId(undefined);
                    },
                  }}
                >
                  {i18n.translation.common.cancel}
                </Button>
              </>
            }
            onSubmit={() => onSubmit}
          >
            {({ register, formState: { errors }, control, setValue, watch }) => {
              // When starting from a system, loads the projects and sets the correct one, when a new one is created as well
              useEffect(() => {
                triggerProjects().then((items) => {
                  if (newProjectId) {
                    setValue("project", items.find((x) => x.id === newProjectId) ?? null);
                  }
                });
              }, [newProjectId]);

              // when starting from a project, it loads the full project because the language is needed
              useEffect(() => {
                if (project) {
                  setValue("project", project);
                }
              }, [project]);

              const selectedProject = watch("project");
              const selectedLanguage = watch("language");
              useEffect(() => {
                if (selectedProject && !selectedLanguage) {
                  setValue("language", languages.find((x) => x.value == selectedProject?.language) ?? null);
                }
              }, [selectedProject?.id]);

              return (
                <FormFieldsContainer fullWidth>
                  {!initialSystemId && (
                    <Controller
                      name="system"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={defaultValues.system}
                      render={({ field, fieldState }) => (
                        <FormField label={i18n.translation.systems.singular} error={fieldState.error}>
                          {({ labelId }) => (
                            <ReactSelect
                              {...field}
                              inputId={labelId}
                              options={systems}
                              placeholder={`${i18n.translation.common.select}...`}
                              isDisabled={isLoadingSystems || !!isErrorSystems || isSubmitting}
                              isLoading={isLoadingSystems || isSubmitting}
                              getOptionValue={(x) => x.id}
                              getOptionLabel={(x) => x.name}
                              styles={reactSelectStyles<System>()}
                              onChange={(selectedOption) => setValue("system", selectedOption ?? null)}
                            />
                          )}
                        </FormField>
                      )}
                    />
                  )}
                  {!initialProjectId && (
                    <Controller
                      name="project"
                      control={control}
                      rules={{ required: true }}
                      defaultValue={defaultValues.project}
                      render={({ field, fieldState }) => (
                        <FormField label={i18n.translation.projects.singular} error={fieldState.error}>
                          {({ labelId }) => (
                            <div className={styles.selectWithButton}>
                              <ReactSelect
                                {...field}
                                inputId={labelId}
                                options={myProjects}
                                placeholder={`${i18n.translation.common.select}...`}
                                isDisabled={(!initialProjectId && isLoadingProjects) || isSubmitting}
                                isLoading={(!initialProjectId && isLoadingProjects) || isSubmitting}
                                getOptionValue={(x) => x.id}
                                getOptionLabel={(x) => x.name}
                                styles={reactSelectStyles<SearchProject>()}
                                onChange={(selectedOption) => setValue("project", selectedOption ?? null)}
                              />
                              <Button
                                buttonProps={{
                                  title: i18n.translation.projects.createNew,
                                  className: styles.button,
                                  disabled: isSubmitting,
                                  onClick: () => setPage("project"),
                                }}
                                glyph={SvgAdd}
                              />
                            </div>
                          )}
                        </FormField>
                      )}
                    />
                  )}
                  <FormField label={i18n.translation.common.name} error={errors.name}>
                    {({ labelId, isOptional, isInvalid }) => (
                      <Input
                        id={labelId}
                        isInvalid={isInvalid}
                        disabled={isSubmitting}
                        {...register("name", {
                          required: !isOptional,
                          minLength: 1,
                        })}
                      />
                    )}
                  </FormField>
                  <Controller
                    name="language"
                    control={control}
                    rules={{ required: !!watch("project") }}
                    defaultValue={defaultValues.language}
                    render={({ field, fieldState }) => (
                      <FormField label={i18n.translation.common.languages.singular} error={fieldState.error}>
                        {({ labelId }) => (
                          <ReactSelect
                            {...field}
                            inputId={labelId}
                            options={languages}
                            placeholder={i18n.translation.common.selectProjectFirst}
                            isDisabled={!selectedProject || isSubmitting}
                            isLoading={isSubmitting}
                            getOptionLabel={(x) => x.display}
                            styles={reactSelectStyles<Language>()}
                            onChange={(value) => setValue("language", value ?? null)}
                          />
                        )}
                      </FormField>
                    )}
                  />
                  <FormField label={i18n.translation.common.description} error={errors.description} isOptional>
                    {({ labelId, isOptional, isInvalid }) => (
                      <Textarea
                        id={labelId}
                        isInvalid={isInvalid}
                        disabled={isSubmitting}
                        {...register("description", {
                          required: !isOptional,
                        })}
                      />
                    )}
                  </FormField>
                </FormFieldsContainer>
              );
            }}
          </Form>
        )}
        {page == "project" && (
          <CreateProjectForm
            onSubmit={(projectId: string) => {
              setIsSubmitting(false);
              setNewProjectId(projectId);
              setPage("configuration");
            }}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
            closeModal={() => setPage("configuration")}
          />
        )}
      </ModalDialog>
      <Button
        glyph={SvgAdd}
        buttonProps={{
          disabled: props.disabled,
          onClick: () => {
            setModalOpen(true);
            initialProjectId && triggerProject();
            !initialSystemId && !systems && triggerSystems();
          },
        }}
      >
        {props.title}
      </Button>
    </>
  );
};

export default CreateConfigurationButton;
