import { useCallback } from "react";
import useSWRImmutable from "swr/immutable";
import useSWRMutation from "swr/mutation";
import { Paginated, PaginationQuery } from "../types";
import { buildUrl, mutateCache, useApi } from "./api";
import { CustomError } from "./errors";
import { UpdateUserBody, User } from "./types/users";

export const useUsersApi = () => {
  const api = useApi();

  const getAdminUsers = useCallback(
    (query: PaginationQuery, signal: AbortSignal) => {
      const url = buildUrl("/admin/users", query);
      return useSWRImmutable<Paginated<User>, CustomError, string>(url, (url) =>
        api.get<Paginated<User>>({ url, signal })
      );
    },
    [api]
  );

  const getAdminUser = useCallback(
    (id: string, signal: AbortSignal) => {
      const url = `/admin/users/${id}`;
      return useSWRMutation<User, CustomError, string>(url, (url) => api.get<User>({ url, signal }));
    },
    [api]
  );

  const updateUser = useCallback(
    async (itemId: string, body: UpdateUserBody): Promise<void> => {
      const url = `/admin/users/${itemId}`;
      await api.put<UpdateUserBody>(url, body);
      mutateCache(["/systems"], url);
    },
    [api]
  );

  return { getAdminUsers, getAdminUser, updateUser: updateUser };
};
