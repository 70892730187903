import classNames from "classnames";

import { FC, useCallback, useState } from "react";
import useI18n from "../../hooks/useTranslations";
import styles from "./DragAndDrop.module.css";
import LoadingSpinner from "./LoadingSpinner";

interface DragAndDropProps {
  element: JSX.Element;
  onDrop: (files: FileList, onDone: () => void) => void;
}

const DragAndDrop: FC<DragAndDropProps> = ({ element, onDrop }) => {
  const i18n = useI18n();
  const [isDragging, setIsDragging] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleDragOver = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      !isDragging && setIsDragging(true);
    },
    [isDragging]
  );

  const handleDragLeave = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      e.stopPropagation();
      isDragging && setIsDragging(false);
    },
    [isDragging]
  );

  const handleDrop = useCallback((e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    if (e.dataTransfer.files) {
      setIsSubmitting(true);
      onDrop(e.dataTransfer.files, () => {
        setIsSubmitting(false);
      });
    }
  }, []);

  return (
    <div
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
      className={classNames(styles.dragAndDropContainer, { [styles.drop]: isDragging || isSubmitting })}
    >
      <div className={styles.dragAndDrop}>
        {isDragging && !isSubmitting && <div>{i18n.translation.common.dropIt}</div>}
        {isSubmitting && (
          <div>
            <LoadingSpinner />
          </div>
        )}
      </div>
      {!isDragging && !isSubmitting && element}
    </div>
  );
};

export default DragAndDrop;
