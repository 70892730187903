import { FC, useContext, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import NotificationDispatch, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import useI18n from "../../hooks/useTranslations";
import { useClientsApi } from "../../http/clients";
import { FullClient, UpsertClientBody } from "../../http/types/clients";
import { useRolesApi } from "../../http/useRoles";
import { generateSecret } from "../../utils";
import SvgAdd from "../icons/Add";
import SvgClose from "../icons/Close";
import Button from "../ui/Button";
import Form from "../ui/Form";
import FormField from "../ui/FormField";
import FormFieldsContainer from "../ui/FormFieldsContainer";
import Header from "../ui/Header";
import Input from "../ui/Input";
import MultiCheckbox from "../ui/MultiCheckbox";
import ScrollContent from "../ui/ScrollContent";

export const NewAdminClient: FC = () => {
  const i18n = useI18n();

  return (
    <>
      <Header
        title={i18n.translation.clients.createNew}
        path={[
          { display: i18n.translation.clients.all, url: "/admin/clients" },
          { display: i18n.translation.clients.createNew },
        ]}
      />

      <NewEditAdminClient />
    </>
  );
};

export const EditAdminClient: FC = () => {
  const i18n = useI18n();
  const { id } = useParams<{ id: string }>();
  const clientsApi = useClientsApi();

  const aborter = useRef(new AbortController());

  const clientSwr = clientsApi.getClient(id!, aborter.current.signal);

  useEffect(() => {
    id && clientSwr.trigger();
  }, [id]);

  if (!clientSwr.data && !clientSwr.error) {
    return null;
  }

  return (
    <>
      <Header
        title={clientSwr.data?.name ?? i18n.translation.common.loading}
        error={clientSwr.error}
        path={[
          { display: i18n.translation.clients.all, url: "/admin/clients" },
          { display: clientSwr.data?.name ?? "" },
        ]}
      />

      <NewEditAdminClient
        client={!clientSwr.error ? clientSwr.data : undefined}
        disabled={clientSwr.isMutating || !!clientSwr.error}
        isLoading={clientSwr.isMutating}
      />
    </>
  );
};

interface AdminClientFormBody {
  name: string | null;
  secrets: {
    id: string | null;
    name: string;
    value: string;
  }[];
  permissions: string[];
}

const secretLength = 32;

interface NewEditAdminClientProps {
  client?: FullClient;
  disabled?: boolean;
  isLoading?: boolean;
}

const NewEditAdminClient: FC<NewEditAdminClientProps> = ({ client, disabled, isLoading }: NewEditAdminClientProps) => {
  const i18n = useI18n();
  const dispatch = useContext(NotificationDispatch);
  const navigate = useNavigate();
  const clientsApi = useClientsApi();
  const rolesApi = useRolesApi();

  const [isSubmitting, setIsSubmitting] = useState(false);

  const [secretErrors, setSecretErrors] = useState<boolean[]>([]);

  const defaultValues: AdminClientFormBody = {
    name: client?.name ?? null,
    secrets:
      client?.secrets.map((x) => ({ id: x.id, name: x.name, value: "*".repeat(secretLength - 3) + x.suffix })) ?? [],
    permissions: client?.permissions ?? [],
  };

  const rolesSwr = rolesApi.getAdminRoles();

  useEffect(() => {
    rolesSwr.trigger();
  }, []);

  useEffect(() => {
    rolesSwr.error && dispatch(showErrorNotification(i18n, rolesSwr.error));
  }, [rolesSwr.error]);

  const ps = rolesSwr.data?.reduce((rv: Map<string, { id: string; title: string }>, v) => {
    for (const p of v.permissions) {
      !rv.has(p) && rv.set(p, { id: p, title: p });
    }
    return rv;
  }, new Map());
  const allPermissions = ps ? Array.from(ps.values()) : [];

  const onSubmit = () => (formData: AdminClientFormBody) => {
    setIsSubmitting(true);

    const body: UpsertClientBody = {
      name: formData.name ?? "",
      secrets: formData.secrets,
      permissions: formData.permissions,
    };

    client?.id
      ? clientsApi
          .updateClient(client.id, body)
          .then(() => dispatch(showSuccessNotification(i18n.translation)))
          .catch((err) => dispatch(showErrorNotification(i18n, err)))
          .finally(() => setIsSubmitting(false))
      : clientsApi
          .insertClient(body)
          .then((res) => navigate(`/admin/clients/${res.id}`, { replace: true }))
          .catch((err) => dispatch(showErrorNotification(i18n, err)))
          .finally(() => setIsSubmitting(false));
  };

  return (
    <ScrollContent direction="row">
      <Form<AdminClientFormBody>
        submitText={i18n.translation.common.save}
        disabled={disabled || isSubmitting}
        defaultValues={defaultValues}
        onSubmit={onSubmit}
        fullWidth
      >
        {({ register, formState: { errors }, reset, watch, setValue, control }) => {
          useEffect(() => {
            reset(defaultValues);
          }, [client]);

          const permissions = watch("permissions");

          return (
            <FormFieldsContainer fullWidth>
              <FormField label={i18n.translation.common.name} error={errors.name}>
                {({ labelId, isOptional, isInvalid }) => (
                  <Input
                    id={labelId}
                    isInvalid={isInvalid}
                    disabled={disabled || isLoading}
                    {...register("name", {
                      required: !isOptional,
                      minLength: 1,
                    })}
                  />
                )}
              </FormField>
              {!!rolesSwr.data && (
                <FormField label={i18n.translation.permissions.plural}>
                  <MultiCheckbox
                    values={allPermissions.map((x) => ({
                      ...x,
                      selected: permissions.includes(x.id),
                    }))}
                    disabled={disabled || isLoading}
                    onUpdate={(id, checked) => {
                      setValue("permissions", checked ? [...permissions, id] : permissions.filter((x) => x !== id));
                    }}
                  />
                </FormField>
              )}
              <Controller
                name="secrets"
                control={control}
                rules={{
                  validate: (values) => {
                    const [es, ok] = values.reduce(
                      (rv: [boolean[], boolean], x) => {
                        const ok = !!x.name;
                        return [[...rv[0], !ok], rv[1] && ok];
                      },
                      [[], true]
                    );
                    setSecretErrors(es);
                    return ok;
                  },
                }}
                render={() => (
                  <FormField label={i18n.translation.secrets.plural}>
                    <div style={{ display: "flex", flexDirection: "column", gap: "1em", flex: 1 }}>
                      {watch("secrets").map((x, index) => (
                        <div style={{ display: "flex", gap: "0.5em", flex: 1 }} key={`secret_${index}`}>
                          <Input
                            value={x.name}
                            isInvalid={secretErrors[index]}
                            onChange={(e) => {
                              const a = watch("secrets").filter((_, i) => {
                                return i != index;
                              });
                              a.splice(index, 0, { ...x, name: e.target.value });
                              setValue("secrets", a);
                            }}
                          />
                          <Input value={x.value} disabled={true} />
                          <Button
                            glyph={SvgClose}
                            buttonProps={{
                              onClick: () => {
                                setSecretErrors([]);
                                setValue(
                                  "secrets",
                                  watch("secrets").filter((_, i) => index !== i)
                                );
                              },
                            }}
                          ></Button>
                        </div>
                      ))}
                    </div>
                  </FormField>
                )}
              />
              <div style={{ display: "flex", justifyContent: "end" }}>
                <Button
                  glyph={SvgAdd}
                  buttonProps={{
                    onClick: () => {
                      setSecretErrors([]);
                      setValue("secrets", [
                        ...watch("secrets"),
                        { id: null, name: "", value: generateSecret(secretLength) },
                      ]);
                    },
                  }}
                ></Button>
              </div>
            </FormFieldsContainer>
          );
        }}
      </Form>
    </ScrollContent>
  );
};
