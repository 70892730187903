import { FC, useContext, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import NotificationDispatch, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import useTabs from "../../hooks/useTabs";
import useI18n from "../../hooks/useTranslations";
import { useApi } from "../../http/api";
import { AdminSystem, UpsertSystemNewImageBody, UpsertSystemRemoveImageBody } from "../../http/types/systems";
import { useSystemsApi } from "../../http/useSystems";
import en from "../../i18n/en";
import { getUILanguages } from "../../types";
import SvgSettings from "../icons/Settings";
import Button from "../ui/Button";
import Form from "../ui/Form";
import FormField from "../ui/FormField";
import FormFieldsContainer from "../ui/FormFieldsContainer";
import Header from "../ui/Header";
import useImageUploader from "../ui/ImageUploader";
import Input from "../ui/Input";
import Numeric from "../ui/Numeric";
import ScrollContent from "../ui/ScrollContent";
import Switch from "../ui/Switch";
import Tabs from "../ui/Tabs";
import Textarea from "../ui/Textarea";

export const NewAdminSystem: FC = () => {
  const i18n = useI18n();

  return (
    <>
      <Header
        title={i18n.translation.systems.new}
        path={[
          { display: i18n.translation.systems.all, url: "/admin/systems" },
          { display: i18n.translation.systems.new },
        ]}
      />
      <NewEditAdminSystem />
    </>
  );
};

export const EditAdminSystem: FC = () => {
  const navigate = useNavigate();
  const i18n = useI18n();
  const { id } = useParams<{ id: string }>();
  const systemsApi = useSystemsApi();

  const aborter = useRef(new AbortController());
  const { data, error, isLoading } = systemsApi.getAdminSystem(id!, aborter.current.signal);

  return (
    <>
      <Header
        title={data?.name!}
        error={error}
        path={[{ display: i18n.translation.systems.all, url: "/admin/systems" }, { display: data?.name! }]}
        buttons={
          <Button
            buttonProps={{
              disabled: !!error,
              onClick: () => navigate(`/admin/systems/${id}/settings`),
            }}
            glyph={SvgSettings}
          >
            {i18n.translation.common.settings}
          </Button>
        }
      />
      <NewEditAdminSystem system={!error ? data : undefined} disabled={isLoading || !!error} />
    </>
  );
};

export interface AdminSystemFormBody {
  name: string;
  nameIt?: string;
  nameDe?: string;
  nameEs?: string;
  nameFr?: string;
  descriptionShort: string;
  descriptionShortIt?: string;
  descriptionShortDe?: string;
  descriptionShortEs?: string;
  descriptionShortFr?: string;
  descriptionLong: string;
  descriptionLongIt?: string;
  descriptionLongDe?: string;
  descriptionLongEs?: string;
  descriptionLongFr?: string;
  isVisible: boolean;
  order: number | null;
  image?: string;
}

export interface AdminSystemFormProps {
  system?: AdminSystem;
  disabled?: boolean;
}

const NewEditAdminSystem: FC<AdminSystemFormProps> = ({ system, disabled }: AdminSystemFormProps) => {
  const i18n = useI18n();
  const api = useApi();
  const dispatch = useContext(NotificationDispatch);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const systemsApi = useSystemsApi();

  const languages = getUILanguages(i18n.translation);
  const controller = useTabs(languages.length, "l_tab");
  const [tabs, setTabs] = useState(languages.map((x) => ({ title: x.display, value: x.value })));

  const { component, newImage, removeImage, onSave } = useImageUploader({ defaultImage: system?.image, disabled });

  useEffect(() => {
    setTabs(languages.map((x) => ({ title: x.display, value: x.value })));
  }, [i18n.translation]);

  const defaultValues: AdminSystemFormBody = {
    name: system?.name ?? "",
    nameIt: system?.nameIt,
    nameEs: system?.nameEs,
    nameFr: system?.nameFr,
    nameDe: system?.nameDe,
    descriptionShort: system?.descriptionShort ?? "",
    descriptionShortIt: system?.descriptionShortIt,
    descriptionShortDe: system?.descriptionShortDe,
    descriptionShortEs: system?.descriptionShortEs,
    descriptionShortFr: system?.descriptionShortFr,
    descriptionLong: system?.descriptionLong ?? "",
    descriptionLongIt: system?.descriptionLongIt,
    descriptionLongDe: system?.descriptionLongDe,
    descriptionLongEs: system?.descriptionLongEs,
    descriptionLongFr: system?.descriptionLongFr,
    order: system?.order ?? 50,
    isVisible: !!system?.isVisible,
    image: system?.image,
  };

  const submit = (body: UpsertSystemRemoveImageBody | UpsertSystemNewImageBody) => {
    (system ? systemsApi.updateAdminSystem(system.id, body) : systemsApi.insertAdminSystem(body))
      .then((res) => {
        setIsSubmitting(false);
        onSave();
        res
          ? navigate(`/admin/systems/${res.id}`, { replace: true })
          : dispatch(showSuccessNotification(i18n.translation));
      })
      .catch((err) => {
        dispatch(showErrorNotification(i18n, err));
        setIsSubmitting(false);
      });
  };

  const onSubmitForm = (formData: AdminSystemFormBody) => {
    setIsSubmitting(true);
    if (newImage) {
      systemsApi
        .generateUploadImageUrl(system?.id!, newImage.name)
        .then((uploadPolicy) => {
          api
            .uploadFile(uploadPolicy, newImage)
            .then((res) => {
              if (!res.ok) {
                dispatch(showErrorNotification(i18n, res));
                setIsSubmitting(false);
                return;
              }
              const body: UpsertSystemNewImageBody = {
                name: formData.name!,
                nameIt: formData.nameIt!,
                nameEs: formData.nameEs!,
                nameFr: formData.nameFr!,
                nameDe: formData.nameDe!,
                descriptionShort: formData.descriptionShort!,
                descriptionShortIt: formData.descriptionShortIt!,
                descriptionShortDe: formData.descriptionShortDe!,
                descriptionShortEs: formData.descriptionShortEs!,
                descriptionShortFr: formData.descriptionShortFr!,
                descriptionLong: formData.descriptionLong!,
                descriptionLongIt: formData.descriptionLongIt!,
                descriptionLongDe: formData.descriptionLongDe!,
                descriptionLongEs: formData.descriptionLongEs!,
                descriptionLongFr: formData.descriptionLongFr!,
                order: formData.order!,
                isVisible: !!formData.isVisible,
                image: newImage.name,
              };
              submit(body);
            })
            .catch((err) => {
              dispatch(showErrorNotification(i18n, err));
              setIsSubmitting(false);
            });
        })
        .catch((err) => {
          dispatch(showErrorNotification(i18n, err));
          setIsSubmitting(false);
        });
    } else {
      const body: UpsertSystemRemoveImageBody = {
        name: formData.name!,
        nameIt: formData.nameIt!,
        nameEs: formData.nameEs!,
        nameFr: formData.nameFr!,
        nameDe: formData.nameDe!,
        descriptionShort: formData.descriptionShort!,
        descriptionShortIt: formData.descriptionShortIt!,
        descriptionShortDe: formData.descriptionShortDe!,
        descriptionShortEs: formData.descriptionShortEs!,
        descriptionShortFr: formData.descriptionShortFr!,
        descriptionLong: formData.descriptionLong!,
        descriptionLongIt: formData.descriptionLongIt!,
        descriptionLongDe: formData.descriptionLongDe!,
        descriptionLongEs: formData.descriptionLongEs!,
        descriptionLongFr: formData.descriptionLongFr!,
        order: formData.order!,
        isVisible: !!formData.isVisible,
        removeImage: removeImage,
      };
      submit(body);
    }
  };

  const panel = (
    <ScrollContent direction="row">
      <Form<AdminSystemFormBody>
        submitText={i18n.translation.common.save}
        // disable only if it is a new system
        disabled={(isSubmitting && !system) || disabled}
        defaultValues={defaultValues}
        onSubmit={() => onSubmitForm}
      >
        {({ register, formState: { errors }, control, watch, setValue, reset }) => {
          useEffect(() => {
            reset(defaultValues);
          }, [system]);

          useEffect(() => {
            setTabs((prevState) =>
              prevState.map((x) => {
                return {
                  ...x,
                  hasError:
                    x.value === en.languageCode && (errors.name || errors.descriptionShort || errors.descriptionLong),
                };
              })
            );
          }, [errors.name, errors.descriptionShort, errors.descriptionLong]);

          const visible = !!watch("isVisible");
          return (
            <FormFieldsContainer imageUploader={component} tabbed>
              <FormField
                label={i18n.translation.common.name}
                error={errors.name}
                hidden={controller.selectedIndex != 0}
              >
                {({ labelId, isOptional, isInvalid }) => (
                  <Input
                    id={labelId}
                    isInvalid={isInvalid}
                    disabled={disabled}
                    {...register("name", {
                      required: !isOptional,
                    })}
                  />
                )}
              </FormField>
              <FormField label={i18n.translation.common.name} isOptional hidden={controller.selectedIndex != 1}>
                {({ labelId, isOptional, isInvalid }) => (
                  <Input
                    id={labelId}
                    isInvalid={isInvalid}
                    disabled={disabled}
                    {...register("nameIt", {
                      required: !isOptional,
                    })}
                  />
                )}
              </FormField>
              <FormField label={i18n.translation.common.name} isOptional hidden={controller.selectedIndex != 2}>
                {({ labelId, isOptional, isInvalid }) => (
                  <Input
                    id={labelId}
                    isInvalid={isInvalid}
                    disabled={disabled}
                    {...register("nameDe", {
                      required: !isOptional,
                    })}
                  />
                )}
              </FormField>
              <FormField label={i18n.translation.common.name} isOptional hidden={controller.selectedIndex != 3}>
                {({ labelId, isOptional, isInvalid }) => (
                  <Input
                    id={labelId}
                    isInvalid={isInvalid}
                    disabled={disabled}
                    {...register("nameEs", {
                      required: !isOptional,
                    })}
                  />
                )}
              </FormField>
              <FormField label={i18n.translation.common.name} isOptional hidden={controller.selectedIndex != 4}>
                {({ labelId, isOptional, isInvalid }) => (
                  <Input
                    id={labelId}
                    isInvalid={isInvalid}
                    disabled={disabled}
                    {...register("nameFr", {
                      required: !isOptional,
                    })}
                  />
                )}
              </FormField>
              <FormField
                label={i18n.translation.common.descriptionShort}
                error={errors.descriptionShort}
                hidden={controller.selectedIndex != 0}
              >
                {({ labelId, isOptional, isInvalid }) => (
                  <Textarea
                    id={labelId}
                    rows={4}
                    isInvalid={isInvalid}
                    disabled={disabled}
                    {...register("descriptionShort", {
                      required: !isOptional,
                    })}
                  />
                )}
              </FormField>
              <FormField
                label={i18n.translation.common.descriptionShort}
                isOptional
                hidden={controller.selectedIndex != 1}
              >
                {({ labelId, isOptional, isInvalid }) => (
                  <Textarea
                    id={labelId}
                    rows={4}
                    isInvalid={isInvalid}
                    disabled={disabled}
                    {...register("descriptionShortIt", {
                      required: !isOptional,
                    })}
                  />
                )}
              </FormField>
              <FormField
                label={i18n.translation.common.descriptionShort}
                isOptional
                hidden={controller.selectedIndex != 2}
              >
                {({ labelId, isOptional, isInvalid }) => (
                  <Textarea
                    id={labelId}
                    rows={4}
                    isInvalid={isInvalid}
                    disabled={disabled}
                    {...register("descriptionShortDe", {
                      required: !isOptional,
                    })}
                  />
                )}
              </FormField>
              <FormField
                label={i18n.translation.common.descriptionShort}
                isOptional
                hidden={controller.selectedIndex != 3}
              >
                {({ labelId, isOptional, isInvalid }) => (
                  <Textarea
                    id={labelId}
                    rows={4}
                    isInvalid={isInvalid}
                    disabled={disabled}
                    {...register("descriptionShortEs", {
                      required: !isOptional,
                    })}
                  />
                )}
              </FormField>
              <FormField
                label={i18n.translation.common.descriptionShort}
                isOptional
                hidden={controller.selectedIndex != 4}
              >
                {({ labelId, isOptional, isInvalid }) => (
                  <Textarea
                    id={labelId}
                    rows={4}
                    isInvalid={isInvalid}
                    disabled={disabled}
                    {...register("descriptionShortFr", {
                      required: !isOptional,
                    })}
                  />
                )}
              </FormField>
              <FormField
                label={i18n.translation.common.descriptionLong}
                error={errors.descriptionLong}
                hidden={controller.selectedIndex != 0}
              >
                {({ labelId, isOptional, isInvalid }) => (
                  <Textarea
                    id={labelId}
                    rows={15}
                    isInvalid={isInvalid}
                    disabled={disabled}
                    {...register("descriptionLong", {
                      required: !isOptional,
                    })}
                  />
                )}
              </FormField>
              <FormField
                label={i18n.translation.common.descriptionLong}
                isOptional
                hidden={controller.selectedIndex != 1}
              >
                {({ labelId, isOptional, isInvalid }) => (
                  <Textarea
                    id={labelId}
                    rows={15}
                    isInvalid={isInvalid}
                    disabled={disabled}
                    {...register("descriptionLongIt", {
                      required: !isOptional,
                    })}
                  />
                )}
              </FormField>
              <FormField
                label={i18n.translation.common.descriptionLong}
                isOptional
                hidden={controller.selectedIndex != 2}
              >
                {({ labelId, isOptional, isInvalid }) => (
                  <Textarea
                    id={labelId}
                    rows={15}
                    isInvalid={isInvalid}
                    disabled={disabled}
                    {...register("descriptionLongDe", {
                      required: !isOptional,
                    })}
                  />
                )}
              </FormField>
              <FormField
                label={i18n.translation.common.descriptionLong}
                isOptional
                hidden={controller.selectedIndex != 3}
              >
                {({ labelId, isOptional, isInvalid }) => (
                  <Textarea
                    id={labelId}
                    rows={15}
                    isInvalid={isInvalid}
                    disabled={disabled}
                    {...register("descriptionLongEs", {
                      required: !isOptional,
                    })}
                  />
                )}
              </FormField>
              <FormField
                label={i18n.translation.common.descriptionLong}
                isOptional
                hidden={controller.selectedIndex != 4}
              >
                {({ labelId, isOptional, isInvalid }) => (
                  <Textarea
                    id={labelId}
                    rows={15}
                    isInvalid={isInvalid}
                    disabled={disabled}
                    {...register("descriptionLongFr", {
                      required: !isOptional,
                    })}
                  />
                )}
              </FormField>
              <FormField label={i18n.translation.common.isVisible}>
                {({ labelId }) => (
                  <Switch
                    id={labelId}
                    checked={visible}
                    disabled={disabled || !system?.type || controller.selectedIndex != 0}
                    onChange={(checked) => setValue("isVisible", checked)}
                  />
                )}
              </FormField>
              <Controller
                name="order"
                control={control}
                rules={{ required: true, min: 1 }}
                render={({ field, fieldState }) => (
                  <FormField label={i18n.translation.common.order} error={fieldState.error}>
                    {({ labelId, isInvalid }) => (
                      <Numeric
                        {...field}
                        id={labelId}
                        isInvalid={isInvalid}
                        disabled={disabled || controller.selectedIndex != 0}
                        decimalScale={0}
                        allowNegative={false}
                        onBlur={(value) => setValue("order", value)}
                      />
                    )}
                  </FormField>
                )}
              />
            </FormFieldsContainer>
          );
        }}
      </Form>
    </ScrollContent>
  );

  return <Tabs controller={controller} tabs={tabs} panels={languages.map(() => panel)} />;
};
