import { FC } from "react";

interface FileIconProps {
  fileName?: string;
}

const types: Map<string, string> = new Map([
  ["xlsx", "xls"],
  ["xls", "xls"],
  ["docx", "doc"],
  ["doct", "doc"],
  ["doc", "doc"],
  ["pdf", "pdf"],
  ["png", "img"],
  ["jpg", "img"],
  ["gif", "img"],
  ["jpeg", "img"],
  ["tif", "img"],
  ["zip", "zip"],
  ["rar", "zip"],
  ["mp3", "audio"],
  ["mp4", "video"],
  ["avi", "video"],
  ["svg", "svg"],
]);

export const isImage = (fileName: string) => {
  return ["png", "jpg", "gif", "jpeg", "tif", "svg"].some((x) => fileName.toLocaleLowerCase().split(".").pop() === x);
};
export const isVideo = (fileName: string) => {
  return ["mp3", "mp4", "avi"].some((x) => fileName.toLocaleLowerCase().split(".").pop() === x);
};
export const isEmbed = (fileName: string) => {
  return ["pdf"].some((x) => fileName.toLocaleLowerCase().split(".").pop() === x);
};

const FileIcon: FC<FileIconProps> = ({ fileName }: FileIconProps) => {
  const extension = fileName?.substring(fileName.lastIndexOf(".") + 1).toLowerCase() || "";
  const type = types.get(extension);

  return <img src={window.location.origin + `/${type ?? "generic"}.svg`} />;
};

export default FileIcon;
