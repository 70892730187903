import { FC, PropsWithChildren, useContext, useState } from "react";
import NotificationDispatch, { showErrorNotification } from "../../context/notificationContext";
import useI18n from "../../hooks/useTranslations";
import { useApi } from "../../http/api";
import styles from "./DownloadLink.module.css";
import { isEmbed, isImage, isVideo } from "./FileIcon";
import ModalDialog from "./ModalDialog";

type DownloadLinkProps = PropsWithChildren<{
  url: string;
  fileName: string;
}>;

const DownloadLink: FC<DownloadLinkProps> = ({ children, url, fileName }) => {
  const i18n = useI18n();
  const api = useApi();
  const dispatch = useContext(NotificationDispatch);
  const [docUrl, setDocUrl] = useState<string>();

  const userAgent = window.navigator.userAgent;
  const isMobile = /Mobi|Android/i.test(userAgent);

  const onClick = async () => {
    if (!isMobile && (isVideo(url) || isImage(url) || isEmbed(url))) {
      const docUrl = await api.createObjectURL(url + `?t=${new Date().getTime()}`, true);
      setDocUrl(docUrl);
      return;
    }
    api.downloadFile(url + `?t=${new Date().getTime()}`, fileName);
  };

  return (
    <>
      <a
        href={url}
        onClick={(e) => {
          e.preventDefault();
          onClick().catch((err) => dispatch(showErrorNotification(i18n, err)));
          return false;
        }}
      >
        {children}
      </a>
      <ModalDialog full isOpen={!!docUrl} onClose={() => setDocUrl(undefined)} title={fileName}>
        <div className={styles.embedDoc}>
          {isEmbed(fileName) ? (
            <embed src={docUrl} width="100%" height="100%" />
          ) : isVideo(url) ? (
            <video src={docUrl} controls />
          ) : (
            <img src={docUrl} />
          )}
        </div>
      </ModalDialog>
    </>
  );
};

export default DownloadLink;
