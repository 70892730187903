import { Translation } from "../../hooks/useTranslations";
import { UserLight } from "./users";

export type Project = {
  id: string;
  name: string;
  language: string;
  status: string;
  description?: string;
  address?: string;
  coordinates?: string;
  company?: string;
  contactPerson?: string;
  author: UserLight;
  createdAt: string;
  editor: UserLight;
  modifiedAt: string;
};

export type SearchAdminProject = {
  id: string;
  name: string;
  address?: string;
  author: UserLight;
  createdAt: string;
  editor: UserLight;
  modifiedAt: string;
  status: string;
};

export type SearchProject = {
  id: string;
  name: string;
  address?: string;
  author: UserLight;
  modifiedAt: string;
  language: string;
  status: string;
};

export type InsertProjectBody = {
  name: string;
  description?: string;
  language: string;
  address?: string;
  coordinates?: string;
  company?: string;
  contactPerson?: string;
};

export type UpdateProjectBody = InsertProjectBody & {
  status: string;
};

export type ProjectStatus = {
  value: string;
  display: string;
};

export const getProjectStatusValues = (translations: Translation): ProjectStatus[] => [
  { value: "offer", display: translations.common.offer },
  { value: "confirmed", display: translations.common.confirmed },
  { value: "toBeInstalled", display: translations.common.toBeInstalled },
  { value: "installed", display: translations.common.installed },
  { value: "archived", display: translations.common.archived },
  { value: "toInspect", display: translations.common.toInspect },
];
