import { FC, useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import { default as ReactSelect, default as Select, SingleValue } from "react-select";
import useI18n from "../../../hooks/useTranslations";
import {
  AbsorberForSettings,
  InstallationTypeForSettings,
  LowerElementForSettings,
  SteelVersionForSettings,
  VertigripSettings,
  WallMaterialFixingForSettings,
  WallMaterialForSettings,
} from "../../../http/types/vertigrip/vertigripSettings";
import Button from "../../ui/Button";
import Form from "../../ui/Form";
import FormField from "../../ui/FormField";
import { ItemsHelpModal } from "../../ui/ItemsHelpModal";
import LoadingSpinner from "../../ui/LoadingSpinner";
import reactSelectStyles from "../../ui/ReactSelectUtils";
import ScrollContent from "../../ui/ScrollContent";
import SimpleTextModalDialog from "./SimpleTextModalDialog";
import styles from "./VertigripLowerFixation.module.css";
import { WallMaterialFixingsHelpModal } from "./WallMaterialFixingsHelpModal";
import { WallMaterialsHelpModal } from "./WallMaterialsHelpModal";

export interface BaseHeightOption {
  value: number;
}

export interface VertigripLowerFixationFormBody {
  baseHeight?: BaseHeightOption;
  installationType: InstallationTypeForSettings | null;
  lowerElement: LowerElementForSettings | null;
  wallMaterial: WallMaterialForSettings | null;
  noWallMaterial: boolean;
  fixing: WallMaterialFixingForSettings | null;
  absorber: AbsorberForSettings | null;
}

interface VertigripLowerFixationProps {
  defaultValues?: VertigripLowerFixationFormBody;
  settings?: VertigripSettings;
  steelVersion?: SteelVersionForSettings | null;
  isLoading?: boolean;
  back: () => void;
  next: () => void;
  onSubmit: (body: VertigripLowerFixationFormBody) => Promise<void> | undefined;
  isAdmin?: boolean;
}

const VertigripLowerFixation: FC<VertigripLowerFixationProps> = ({
  defaultValues,
  settings,
  steelVersion,
  isLoading,
  back,
  next,
  onSubmit,
  isAdmin,
}: VertigripLowerFixationProps) => {
  const i18n = useI18n();
  const [showBaseHeightHelpModal, setShowBaseHeightHelpModal] = useState(false);
  const [showInstallationTypesHelpModal, setShowInstallationTypesHelpModal] = useState(false);
  const [showLowerElementsHelpModal, setShowLowerElementsHelpModal] = useState(false);
  const [showAbsorbersHelpModal, setShowAbsorbersHelpModal] = useState(false);
  const [showWallMaterialsHelpModal, setShowWallMaterialsHelpModal] = useState(false);
  const [showWallMaterialFixingsHelpModal, setShowWallMaterialFixingsHelpModal] = useState(false);

  const baseHeightOptions: BaseHeightOption[] = [{ value: 0.3 }, { value: 0.4 }, { value: 0.5 }, { value: 0.6 }];

  const fakeWallMaterial: WallMaterialForSettings = {
    id: "fakeId",
    name: i18n.translation.wallMaterials.noWallMaterial,
    description: "",
    fixings: [],
  };

  return (
    <ScrollContent direction="row">
      <Form<VertigripLowerFixationFormBody>
        mode={"onBlur"}
        defaultValues={defaultValues}
        disabled={isLoading}
        onSubmit={() => (formData) => onSubmit(formData)?.then(() => !isAdmin && next())}
        submitText={isAdmin ? i18n.translation.common.save : i18n.translation.common.next}
        secondaryButton={
          <>
            {isLoading && <LoadingSpinner delayed={0} size="small" />}
            {!isAdmin ? (
              <Button
                buttonProps={{
                  disabled: isLoading,
                  onClick: back,
                }}
                type="secondary"
              >
                {i18n.translation.common.back}
              </Button>
            ) : undefined}
          </>
        }
      >
        {({ setValue, watch, control, handleSubmit, reset }) => {
          const submit = (goToNextTab?: boolean) =>
            !isAdmin &&
            handleSubmit((data) => {
              onSubmit(data)?.then(() => {
                reset(data);
                goToNextTab && next();
              });
            })();

          useEffect(() => {
            defaultValues && reset(defaultValues);
          }, [defaultValues]);

          const installationType = watch("installationType");
          const wallMaterial = watch("wallMaterial");

          return (
            <div className={styles.form}>
              <Controller
                name="baseHeight"
                control={control}
                rules={{ required: true }}
                defaultValue={defaultValues?.baseHeight}
                render={({ field, fieldState }) => (
                  <FormField
                    label={`${i18n.translation.common.baseHeight} [m]`}
                    error={fieldState.error}
                    showHelp={
                      settings?.texts.baseHeight ? { onOpen: () => setShowBaseHeightHelpModal(true) } : undefined
                    }
                  >
                    {({ labelId }) => (
                      <>
                        <ReactSelect
                          {...field}
                          inputId={labelId}
                          options={baseHeightOptions}
                          placeholder={`${i18n.translation.common.select}...`}
                          isDisabled={field.disabled || isLoading}
                          isLoading={isLoading}
                          getOptionValue={(x) => x.value.toLocaleString()}
                          getOptionLabel={(x) => x.value.toLocaleString()}
                          styles={reactSelectStyles<BaseHeightOption>()}
                          onChange={(value) => {
                            setValue(
                              "baseHeight",
                              baseHeightOptions.find((x) => x.value === value?.value)
                            );
                            submit();
                          }}
                        />
                        {!!settings?.texts.baseHeight && (
                          <SimpleTextModalDialog
                            title={i18n.translation.common.baseHeight}
                            text={settings.texts.baseHeight}
                            isOpen={showBaseHeightHelpModal}
                            onClose={() => setShowBaseHeightHelpModal(false)}
                          />
                        )}
                      </>
                    )}
                  </FormField>
                )}
              />
              <Controller
                name="installationType"
                control={control}
                rules={{ required: true }}
                defaultValue={defaultValues?.installationType}
                render={({ field, fieldState }) => (
                  <FormField
                    label={i18n.translation.installationTypes.singular}
                    error={fieldState.error}
                    showHelp={{ onOpen: () => setShowInstallationTypesHelpModal(true) }}
                  >
                    {({ labelId }) => {
                      const updateInstallationType = (
                        value?: InstallationTypeForSettings | SingleValue<InstallationTypeForSettings>
                      ) => {
                        if (value?.id !== field.value?.id) {
                          const installationType = value ?? null;
                          setValue("installationType", installationType);
                          setValue("lowerElement", installationType?.lowerElements[0] ?? null);

                          setValue("wallMaterial", null);
                          setValue("fixing", null);
                          setValue("noWallMaterial", false);

                          submit();
                        }
                      };

                      const installationTypes = steelVersion?.installationTypes ?? [];

                      return (
                        <>
                          <ReactSelect
                            {...field}
                            inputId={labelId}
                            options={installationTypes}
                            placeholder={`${i18n.translation.common.select}...`}
                            isDisabled={field.disabled || isLoading}
                            isLoading={isLoading}
                            getOptionValue={(x) => x.id}
                            getOptionLabel={(x) => x.name}
                            styles={reactSelectStyles<InstallationTypeForSettings>()}
                            onChange={updateInstallationType}
                          />
                          <ItemsHelpModal
                            title={i18n.translation.installationTypes.plural}
                            onClose={() => setShowInstallationTypesHelpModal(false)}
                            isOpen={showInstallationTypesHelpModal}
                            onSelect={(installationType) => {
                              updateInstallationType(installationTypes.find((x) => x.id == installationType.id));
                              setShowInstallationTypesHelpModal(false);
                            }}
                            items={installationTypes.map((x) => ({
                              id: x.id,
                              title: x.name,
                              description: x.description,
                              image: x.image,
                            }))}
                          />
                        </>
                      );
                    }}
                  </FormField>
                )}
              />
              <Controller
                name="lowerElement"
                control={control}
                rules={{ required: true }}
                defaultValue={defaultValues?.lowerElement}
                render={({ field, fieldState }) => (
                  <FormField
                    label={i18n.translation.lowerElements.singular}
                    error={fieldState.error}
                    showHelp={{ onOpen: () => setShowLowerElementsHelpModal(true) }}
                  >
                    {({ labelId }) => {
                      const updateLowerElement = (
                        value?: LowerElementForSettings | SingleValue<LowerElementForSettings>
                      ) => {
                        if (value?.id !== field.value?.id) {
                          setValue("lowerElement", value ?? null);
                          submit();
                        }
                      };

                      const lowerFixations = watch("installationType")?.lowerElements ?? [];

                      return (
                        <>
                          <ReactSelect
                            {...field}
                            inputId={labelId}
                            options={lowerFixations}
                            placeholder={`${i18n.translation.common.select}...`}
                            isDisabled={field.disabled || isLoading}
                            isLoading={isLoading}
                            getOptionValue={(x) => x.id}
                            getOptionLabel={(x) => x.code}
                            styles={reactSelectStyles<LowerElementForSettings>()}
                            onChange={updateLowerElement}
                          />
                          <ItemsHelpModal
                            title={i18n.translation.lowerElements.plural}
                            onClose={() => setShowLowerElementsHelpModal(false)}
                            isOpen={showLowerElementsHelpModal}
                            onSelect={(lowerElement) => {
                              updateLowerElement(lowerFixations.find((x) => x.id === lowerElement.id));
                              setShowLowerElementsHelpModal(false);
                            }}
                            items={lowerFixations.map((x) => ({
                              id: x.id,
                              title: x.code,
                              description: x.description,
                              name: x.name,
                              image: x.image,
                            }))}
                          />
                        </>
                      );
                    }}
                  </FormField>
                )}
              />
              <Controller
                name="absorber"
                control={control}
                rules={{ required: true }}
                defaultValue={defaultValues?.absorber}
                render={({ field, fieldState }) => (
                  <FormField
                    label={i18n.translation.absorbers.singular}
                    error={fieldState.error}
                    showHelp={{ onOpen: () => setShowAbsorbersHelpModal(true) }}
                  >
                    {({ labelId }) => {
                      const updateAbsorber = (value?: AbsorberForSettings | SingleValue<AbsorberForSettings>) => {
                        if (value?.id !== field.value?.id) {
                          setValue("absorber", value ?? null);
                          submit();
                        }
                      };

                      const absorbers = steelVersion?.absorbers ?? [];

                      return (
                        <>
                          <ReactSelect
                            {...field}
                            inputId={labelId}
                            options={absorbers}
                            placeholder={`${i18n.translation.common.select}...`}
                            isDisabled={field.disabled || isLoading}
                            isLoading={isLoading}
                            getOptionValue={(x) => x.id}
                            getOptionLabel={(x) => x.code}
                            styles={reactSelectStyles<AbsorberForSettings>()}
                            onChange={updateAbsorber}
                          />
                          <ItemsHelpModal
                            title={i18n.translation.absorbers.plural}
                            onClose={() => setShowAbsorbersHelpModal(false)}
                            isOpen={showAbsorbersHelpModal}
                            onSelect={(absorber) => {
                              updateAbsorber(absorbers.find((x) => x.id === absorber.id));
                              setShowAbsorbersHelpModal(false);
                            }}
                            items={absorbers.map((x) => ({
                              id: x.id,
                              title: x.code,
                              description: x.description,
                              name: x.name,
                              image: x.image,
                            }))}
                          />
                        </>
                      );
                    }}
                  </FormField>
                )}
              />
              <Controller
                name="wallMaterial"
                control={control}
                render={({ field, fieldState }) => (
                  <FormField
                    label={i18n.translation.wallMaterials.singular}
                    hidden={!installationType?.wallMaterials[0]}
                    error={fieldState.error}
                    showHelp={{ onOpen: () => setShowWallMaterialsHelpModal(true) }}
                    isOptional
                  >
                    {({ labelId }) => {
                      const updateWallMaterial = (
                        value?: WallMaterialForSettings | SingleValue<WallMaterialForSettings>
                      ) => {
                        if (value?.id !== field.value?.id) {
                          setValue("noWallMaterial", value?.id == fakeWallMaterial.id);
                          setValue("wallMaterial", value ?? null);
                          setValue("fixing", value?.fixings[0] ?? null);

                          submit();
                        }
                      };

                      const wallMaterials = installationType?.wallMaterials ?? [];
                      const noWallMaterial = watch("noWallMaterial");

                      return (
                        <>
                          <Select
                            {...field}
                            inputId={labelId}
                            isLoading={isLoading}
                            options={[fakeWallMaterial, ...wallMaterials]}
                            getOptionValue={(x) => x.id}
                            getOptionLabel={(x) => x.name}
                            styles={reactSelectStyles<WallMaterialForSettings>(fieldState.invalid)}
                            onChange={updateWallMaterial}
                            value={wallMaterial ?? (noWallMaterial ? fakeWallMaterial : null)}
                          />
                          <WallMaterialsHelpModal
                            onClose={() => setShowWallMaterialsHelpModal(false)}
                            isOpen={showWallMaterialsHelpModal}
                            onSelect={(selectedOption) => {
                              updateWallMaterial(wallMaterials.find((x) => x.id == selectedOption.id));
                              setShowWallMaterialsHelpModal(false);
                            }}
                            items={wallMaterials}
                          />
                        </>
                      );
                    }}
                  </FormField>
                )}
              />
              <Controller
                name="fixing"
                control={control}
                defaultValue={defaultValues?.fixing}
                disabled={!wallMaterial?.fixings[0] || wallMaterial?.id === fakeWallMaterial.id}
                render={({ field, fieldState }) => (
                  <FormField
                    label={i18n.translation.wallMaterialFixings.singular}
                    disabled={field.disabled}
                    hidden={!installationType?.wallMaterials[0]}
                    error={fieldState.error}
                    showHelp={
                      !wallMaterial?.fixings[0] || wallMaterial?.id === fakeWallMaterial.id
                        ? undefined
                        : { onOpen: () => setShowWallMaterialFixingsHelpModal(true) }
                    }
                  >
                    {({ labelId }) => {
                      const updateWallMaterialFixing = (
                        value?: WallMaterialFixingForSettings | SingleValue<WallMaterialFixingForSettings>
                      ) => {
                        if (value?.id !== field.value?.id) {
                          setValue("fixing", value ?? null);

                          submit();
                        }
                      };

                      return (
                        <>
                          <Select
                            {...field}
                            inputId={labelId}
                            isDisabled={field.disabled || isLoading}
                            isLoading={isLoading}
                            options={wallMaterial?.fixings ?? []}
                            getOptionValue={(x) => x.id}
                            getOptionLabel={(x) => x.name}
                            styles={reactSelectStyles<WallMaterialFixingForSettings>(fieldState.invalid)}
                            onChange={updateWallMaterialFixing}
                          />
                          <WallMaterialFixingsHelpModal
                            onClose={() => setShowWallMaterialFixingsHelpModal(false)}
                            isOpen={showWallMaterialFixingsHelpModal}
                            onSelect={(selectedOption) => {
                              updateWallMaterialFixing(wallMaterial?.fixings?.find((x) => x.id == selectedOption.id));
                              setShowWallMaterialFixingsHelpModal(false);
                            }}
                            items={wallMaterial?.fixings ?? []}
                          />
                        </>
                      );
                    }}
                  </FormField>
                )}
              />
            </div>
          );
        }}
      </Form>
    </ScrollContent>
  );
};

export default VertigripLowerFixation;
