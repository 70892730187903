import { FC, useContext, useRef, useState } from "react";
import NotificationDispatch, { showErrorNotification } from "../../context/notificationContext";
import { useDebounce } from "../../hooks/useDebounce";
import useInitializer from "../../hooks/useInitializer";
import useListFilters from "../../hooks/useListFilters";
import useI18n from "../../hooks/useTranslations";
import { useApi } from "../../http/api";
import { SearchAdminDocument } from "../../http/types/documents";
import { useDocumentsApi } from "../../http/useDocuments";
import { PaginationQuery } from "../../types";
import { formatDate } from "../../utils";
import DownloadLink from "../ui/DownloadLink";
import FileImage from "../ui/FileIcon";
import Header from "../ui/Header";
import ModalDialog from "../ui/ModalDialog";
import ScrollContent from "../ui/ScrollContent";
import Table from "../ui/Table";

const AdminDocuments: FC = () => {
  const i18n = useI18n();
  const api = useApi();
  const documentsApi = useDocumentsApi();

  const dispatch = useContext(NotificationDispatch);
  const filters = useListFilters({ isSearchable: true });
  const aborter = useRef(new AbortController());
  const [documentToDelete, setDocumentToDelete] = useState<SearchAdminDocument>();

  const debouncedValue = useDebounce(filters.state.q);

  const query: PaginationQuery = {
    ...filters.state,
    q: debouncedValue,
  };

  const { data, error } = documentsApi.getAdminProjectDocuments(query, aborter.current.signal);

  const isInitialized = useInitializer(data || error);

  if (!data && !error && !isInitialized) {
    return;
  }

  return (
    <>
      <Header
        title={i18n.translation.documents.all}
        error={error}
        path={[
          {
            display: i18n.translation.documents.plural,
          },
        ]}
      />

      <ScrollContent padding>
        <Table<SearchAdminDocument>
          filters={filters}
          isTall={true}
          searchOptions={{
            placeholder: i18n.translation.documents.search,
            onChange: () => {
              aborter.current.abort();
              aborter.current = new AbortController();
            },
          }}
          // searchButtons={<AddDocumentButton projectId={projectId!}></AddDocumentButton>}
          head={
            <tr>
              <th align="left" style={{ width: 1 }}></th>
              <th align="left">{i18n.translation.common.name}</th>
              <th align="left">{i18n.translation.projects.singular}</th>
              <th align="left">{i18n.translation.common.uploadedBy}</th>
              <th align="left">{i18n.translation.common.uploadedAt}</th>
              <th></th>
            </tr>
          }
          items={data?.items}
          renderRow={(item) => (
            <>
              <Table.IconTd>
                <FileImage fileName={item.name} />
              </Table.IconTd>
              <td>
                <DownloadLink url={`/files/project_documents/${item.id}/${item.name}`} fileName={item.name}>
                  {item.name}
                </DownloadLink>
              </td>
              <td>{item.project.name}</td>
              <td>{item.uploader.name}</td>
              <td>{formatDate(item.uploadedAt)}</td>
            </>
          )}
          rowActions={[
            {
              title: i18n.translation.common.download,
              onClick: (item) =>
                api
                  .downloadFile(`/files/project_documents/${item.id}/${item.name}`, item.name)
                  .catch((err) => dispatch(showErrorNotification(i18n, err))),
            },
            { title: i18n.translation.common.delete, onClick: (item) => setDocumentToDelete(item) },
          ]}
          total={data?.total}
          error={!!error}
        />
        <ModalDialog
          isOpen={!!documentToDelete}
          onClose={() => setDocumentToDelete(undefined)}
          title={i18n.translation.documents.delete}
          actions={[
            {
              title: i18n.translation.common.cancel,
              onClick: () => setDocumentToDelete(undefined),
            },
            {
              title: i18n.translation.common.yes,
              onClick: () => {
                documentToDelete &&
                  documentsApi
                    .deleteProjectDocument(documentToDelete.project.id, documentToDelete.id)
                    .then(() => setDocumentToDelete(undefined))
                    .catch((err) => dispatch(showErrorNotification(i18n, err)));
              },
            },
          ]}
        >
          {i18n.translation.documents.questions.delete}
        </ModalDialog>
      </ScrollContent>
    </>
  );
};

export default AdminDocuments;
