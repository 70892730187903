import classNames from "classnames";
import { FC } from "react";
import { Identity } from "../../context/useAuthContext";
import styles from "./Profile.module.css";

interface Props {
  identity: Identity;
  className?: string;
  layout: "full" | "compact";
}

const getInitialChar = (s: string): string => {
  return s.charAt(0).toUpperCase();
};

export const getUserInitials = ({ name, email }: Identity) => {
  const split = (name ?? email).split(" ");
  const numNames = split.length;

  switch (numNames) {
    case 1:
      return getInitialChar(split[0]!);
    default:
      const first = split[0]!;
      const last = split[numNames - 1]!;
      return getInitialChar(first) + getInitialChar(last);
  }
};

const Profile: FC<Props> = ({ identity, className, layout }: Props) => (
  <div className={classNames(styles.profile, className)}>
    {layout === "full" ? (
      <div className={styles.identity}>
        <div className={styles.name}>{identity.name}</div>
        <div className={styles.email}>{identity.email}</div>
      </div>
    ) : (
      <div className={styles.avatar}>
        <div className={styles.initials}>{getUserInitials(identity)}</div>
      </div>
    )}
  </div>
);

export default Profile;
