import { ChangeEvent, FC, useCallback, useRef, useState } from "react";
import useI18n from "../../hooks/useTranslations";
import Button from "./Button";
import styles from "./FileUploader.module.css";
import Input from "./Input";
import SubmitBar from "./SubmitBar";

interface FileUploaderProps {
  onSave: (file: File) => void;
  onCancel: () => void;
  disabled?: boolean;
}

const FileUploader: FC<FileUploaderProps> = ({ onCancel, onSave, disabled }: FileUploaderProps) => {
  const i18n = useI18n();

  const [isDragging, setIsDragging] = useState(false);

  const imageInputRef = useRef<HTMLInputElement>(null);

  const [file, setFile] = useState<File>();
  const [error, setError] = useState(false);

  const setFileValues = (file?: File | null) => {
    file && setFile(file);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    setFileValues(event.target.files?.[0]);
  };

  const handleDragOver = useCallback(
    (e: React.DragEvent<HTMLLabelElement>) => {
      e.preventDefault();
      e.stopPropagation();
      !isDragging && setIsDragging(true);
    },
    [isDragging]
  );

  const handleDragLeave = useCallback(
    (e: React.DragEvent<HTMLLabelElement>) => {
      e.preventDefault();
      e.stopPropagation();
      isDragging && setIsDragging(false);
    },
    [isDragging]
  );

  const handleDrop = useCallback((e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);

    const file = e.dataTransfer.files?.[0];
    setFileValues(file);
    if (imageInputRef.current) {
      imageInputRef.current.files = e.dataTransfer.files;
    }
  }, []);

  const save = () => {
    setError(!file);
    file && onSave(file);
  };

  return (
    <div>
      <label onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleDrop}>
        <input type="file" onChange={handleFileChange} style={{ display: "none" }} ref={imageInputRef} />
        <Input
          className={styles.fileUploader}
          value={
            isDragging
              ? i18n.translation.common.dropIt
              : file?.name ?? i18n.translation.common.dragAndDropOrUploadAnImage
          }
          isInvalid={error}
          readOnly={true}
          onClick={() => imageInputRef.current?.click()}
        />
      </label>
      <SubmitBar>
        <Button buttonProps={{ disabled: disabled, onClick: () => onCancel() }}>
          {i18n.translation.common.cancel}
        </Button>
        <Button buttonProps={{ disabled: disabled, onClick: save }}>{i18n.translation.common.save}</Button>
      </SubmitBar>
    </div>
  );
};

export default FileUploader;
