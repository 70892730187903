import { FC, useContext, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import useInitializer from "../../hooks/useInitializer";
import useListFilters from "../../hooks/useListFilters";
import { formatBoolean, formatTimeStamp } from "../../utils";

import NotificationDispatch, { showErrorNotification } from "../../context/notificationContext";
import { useAuth } from "../../context/useAuthContext";
import { useDebounce } from "../../hooks/useDebounce";
import useI18n from "../../hooks/useTranslations";
import { isNotFound } from "../../http/errors";
import { SearchAdminItem, SearchItem } from "../../http/types/items";
import { useItemsApi } from "../../http/useItems";
import { PaginationQuery } from "../../types";
import SvgAdd from "../icons/Add";
import Button from "../ui/Button";
import Header from "../ui/Header";
import ModalDialog from "../ui/ModalDialog";
import ScrollContent from "../ui/ScrollContent";
import Table from "../ui/Table";

const AdminItems: FC = () => {
  const i18n = useI18n();
  const navigate = useNavigate();
  const dispatch = useContext(NotificationDispatch);
  const auth = useAuth();
  const filters = useListFilters({ isSearchable: true });
  const aborter = useRef(new AbortController());
  const itemsApi = useItemsApi();
  const debouncedValue = useDebounce(filters.state.q);

  const query: PaginationQuery = {
    ...filters.state,
    q: debouncedValue,
  };

  const { data, error } = itemsApi.useAdminItems(query, aborter.current.signal);

  const [itemToDelete, setItemToDelete] = useState<SearchItem>();

  const isInitialized = useInitializer(data || error);

  if (!data && !error && !isInitialized) {
    return;
  }

  return (
    <>
      <Header
        title={i18n.translation.items.plural}
        path={{ display: i18n.translation.items.all }}
        error={error}
        buttons={
          <Button
            glyph={SvgAdd}
            buttonProps={{
              onClick: () => navigate(`/admin/items/new`),
            }}
          >
            {i18n.translation.common.createNew}
          </Button>
        }
      />

      <ScrollContent padding>
        <Table<SearchAdminItem>
          filters={filters}
          isTall={true}
          searchOptions={{
            placeholder: i18n.translation.items.search,
            onChange: () => {
              aborter.current.abort();
              aborter.current = new AbortController();
            },
          }}
          head={
            <tr>
              <th align="left">{i18n.translation.common.code}</th>
              <th align="left">{i18n.translation.common.description}</th>
              <th align="left">{i18n.translation.common.automaticSync}</th>
              <th align="left">{i18n.translation.common.lastSync}</th>
              <th></th>
            </tr>
          }
          items={data?.items}
          renderRow={(item) => (
            <>
              <td>
                <Link to={`/admin/items/${item.id}`}>{item.code}</Link>
              </td>
              <td>{item.description}</td>
              <td>{formatBoolean(i18n.translation, item.automaticSync)}</td>
              <td>{item.lastSync ? formatTimeStamp(item.lastSync, auth.identity?.language) : undefined}</td>
            </>
          )}
          rowActions={[
            { title: i18n.translation.common.edit, onClick: (item) => navigate(`/admin/items/${item.id}`) },
            {
              title: i18n.translation.common.sync,
              onClick: (item) =>
                itemsApi.syncAdminItem(item.id).catch((err) => {
                  const msg = isNotFound(err) ? i18n.translation.items.errors.doesNotExist : undefined;
                  dispatch(showErrorNotification(i18n, err, msg));
                }),
            },
            { title: i18n.translation.common.delete, onClick: (item) => setItemToDelete(item) },
          ]}
          total={data?.total}
          error={!!error}
        />
        <ModalDialog
          isOpen={!!itemToDelete}
          onClose={() => setItemToDelete(undefined)}
          title={i18n.translation.items.delete}
          actions={[
            {
              title: i18n.translation.common.cancel,
              onClick: () => setItemToDelete(undefined),
            },
            {
              title: i18n.translation.common.yes,
              onClick: () => {
                itemToDelete &&
                  itemsApi
                    .deleteAdminItem(itemToDelete.id)
                    .then(() => setItemToDelete(undefined))
                    .catch((err) => dispatch(showErrorNotification(i18n, err)));
              },
            },
          ]}
        >
          {i18n.translation.items.questions.delete}
        </ModalDialog>
      </ScrollContent>
    </>
  );
};

export default AdminItems;
