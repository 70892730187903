import { useCallback } from "react";
import useSWRMutation from "swr/mutation";
import { useApi } from "./api";
import { CustomError } from "./errors";
import { Role } from "./types/roles";

export const useRolesApi = () => {
  const api = useApi();

  const getAdminRoles = useCallback(() => {
    const url = "/admin/roles";
    return useSWRMutation<Role[], CustomError, string>(url, (url) => api.get<Role[]>({ url }));
  }, [api]);

  return { getAdminRoles };
};
