import { FC } from "react";
import useI18n from "../../hooks/useTranslations";
import ErrorText from "./ErrorText";
import Header from "./Header";
import ScrollContent from "./ScrollContent";

const Error: FC = () => {
  const i18n = useI18n();

  const urlParams = new URLSearchParams(window.location.search);
  const status = urlParams.get("status");
  const code = urlParams.get("c");

  const titleText = status?.startsWith("4") ? `${i18n.translation.common.notFound}` : i18n.translation.common.error;

  const errorText = i18n.errorsMap.get(Number(code)) ?? i18n.translation.common.genericError;

  return (
    <>
      <Header title={titleText} />
      <ScrollContent padding>
        <ErrorText text={errorText} />
      </ScrollContent>
    </>
  );
};

export default Error;
