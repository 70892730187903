import { FC, useEffect } from "react";
import { BrowserRouter, Link, Route, Routes } from "react-router-dom";
import NotificationDispatch, { useNotification } from "../context/notificationContext";
import AuthProvider, { RothoSSOSettings, useAuth } from "../context/useAuthContext";
import useToggle from "../hooks/useToggle";
import useI18n from "../hooks/useTranslations";
import AccountButton from "./AccountButton";
import styles from "./App.module.css";
import { EditAdminClient, NewAdminClient } from "./clients/AdminClient";
import AdminClients from "./clients/AdminClients";
import AdminConfigurations from "./configurations/AdminConfigurations";
import Configuration from "./configurations/Configuration";
import Configurations from "./configurations/Configurations";
import Home from "./home/Home";
import SvgHome from "./icons/Home";
import SvgItems from "./icons/Items";
import SvgList from "./icons/List";
import SvgListCheck from "./icons/ListCheck";
import SvgTextSnippet from "./icons/TextSnippet";
import SvgViewList from "./icons/ViewList";
import SvgViewTimeline from "./icons/ViewTimeline";
import { EditAdminItem, NewAdminItem } from "./items/AdminItem";
import AdminItems from "./items/AdminItems";
import Item from "./items/Item";
import AdminDocuments from "./projects/AdminDocuments";
import AdminProjects from "./projects/AdminProjects";
import Project from "./projects/Project";
import Projects from "./projects/Projects";
import ProjectSharings from "./projects/ProjectSharings";
import SideBar from "./SideBar";
import AdminSettings from "./systems/adminSettings/AdminSettings";
import InstallationType from "./systems/adminSettings/vertigrip/installationTypes/InstallationType";
import {
  EditFixing,
  NewFixing,
} from "./systems/adminSettings/vertigrip/installationTypes/wallMaterials/fixings/Fixing";
import WallMaterial from "./systems/adminSettings/vertigrip/installationTypes/wallMaterials/WallMaterial";
import Normative from "./systems/adminSettings/vertigrip/normatives/Normative";
import SteelVersion from "./systems/adminSettings/vertigrip/steelVersions/SteelVersion";
import SystemType from "./systems/adminSettings/vertigrip/systemTypes/SystemType";
import Text from "./systems/adminSettings/vertigrip/texts/Text";
import { EditAdminSystem, NewAdminSystem } from "./systems/AdminSystem";
import AdminSystems from "./systems/AdminSystems";
import System from "./systems/System";
import CookiesBanner from "./ui/CookiesBanner";
import Error from "./ui/Error";
import ErrorText from "./ui/ErrorText";
import Hamburger from "./ui/Hamburger";
import Header from "./ui/Header";
import LoadingSpinner from "./ui/LoadingSpinner";
import SvgLogo from "./ui/Logo";
import ModalDialog from "./ui/ModalDialog";
import Nav from "./ui/Nav";
import Notification from "./ui/Notification";
import ScrollContent from "./ui/ScrollContent";
import { AdminUser } from "./users/AdminUser";
import Users from "./users/Users";

const App: FC = () => {
  const settings: RothoSSOSettings = {
    clientId: import.meta.env.VITE_CLIENT_ID,
    iamUrl: import.meta.env.VITE_IAM_URL,
  };

  return (
    <BrowserRouter>
      <AuthProvider settings={settings}>
        <AppRoutes></AppRoutes>
      </AuthProvider>
    </BrowserRouter>
  );
};

export default App;

const AppRoutes: FC = () => {
  const i18n = useI18n();
  const auth = useAuth();
  const [{ show, severity, message }, dispatch] = useNotification();
  const sidebar = useToggle();
  const { identity, isExpired, isLoading, logout } = useAuth();

  useEffect(() => {
    // if it is the staging environment the document.titel is changed accordingly
    if (import.meta.env.MODE === "staging" && !document.title.includes(i18n.translation.common.testEnvironment)) {
      document.title += " - " + i18n.translation.common.testEnvironment;
    }
  }, []);

  return (
    <NotificationDispatch.Provider value={dispatch}>
      {isLoading ? (
        <div className={styles.loadingSpinner}>
          <LoadingSpinner />
        </div>
      ) : identity ? (
        <>
          <nav className={styles.nav}>
            <Hamburger onClick={sidebar.toggle} isOpen={sidebar.isOpen}></Hamburger>
            <div className={styles.logo}>
              <SvgLogo />
            </div>
            <div className={styles.title}>
              {import.meta.env.MODE === "staging" && (
                <span className={styles.mode}>{i18n.translation.common.testEnvironment}</span>
              )}
            </div>
            <AccountButton />
          </nav>
          <div className={styles.content}>
            <SideBar isOpen={sidebar.isOpen} close={sidebar.close}>
              <Nav>
                <Nav.Group title={i18n.translation.common.myArea}>
                  <Nav.Item text={i18n.translation.common.home} to="/" icon={SvgHome} onClick={sidebar.close} />
                  <Nav.Item text={i18n.translation.projects.my} to="/projects" icon={SvgList} onClick={sidebar.close} />
                  <Nav.Item
                    text={i18n.translation.configurations.my}
                    to="/configurations"
                    icon={SvgListCheck}
                    onClick={sidebar.close}
                  />
                </Nav.Group>
                {!!(
                  auth.permissions.admin.canManageProjects ||
                  auth.permissions.admin.canManageSystems ||
                  auth.permissions.admin.canManageUsers ||
                  auth.permissions.admin.canManageItems
                ) && (
                  <Nav.Group title={i18n.translation.common.administration}>
                    {!!auth.permissions.admin.canManageProjects && (
                      <>
                        <Nav.Item
                          text={i18n.translation.projects.plural}
                          to="/admin/projects"
                          icon={SvgList}
                          onClick={sidebar.close}
                        />
                        <Nav.Item
                          text={i18n.translation.documents.plural}
                          to="/admin/documents"
                          icon={SvgTextSnippet}
                          onClick={sidebar.close}
                        />
                        <Nav.Item
                          text={i18n.translation.configurations.plural}
                          to="/admin/configurations"
                          icon={SvgListCheck}
                          onClick={sidebar.close}
                        />
                      </>
                    )}
                    {!!auth.permissions.admin.canManageSystems && (
                      <Nav.Item
                        text={i18n.translation.systems.plural}
                        to="/admin/systems"
                        icon={SvgViewList}
                        onClick={sidebar.close}
                      />
                    )}
                    {!!auth.permissions.admin.canManageItems && (
                      <Nav.Item
                        text={i18n.translation.items.plural}
                        to="/admin/items"
                        icon={SvgItems}
                        onClick={sidebar.close}
                      />
                    )}
                    {!!auth.permissions.admin.canManageClients && (
                      <Nav.Item
                        text={i18n.translation.clients.plural}
                        to="/admin/clients"
                        icon={SvgViewTimeline}
                        onClick={sidebar.close}
                      />
                    )}
                  </Nav.Group>
                )}
                <div className={styles.cookiesPolicy}>
                  <Link to="https://www.rothoblaas.it/cookie-policy" target="_blank">
                    Cookies-Policy
                  </Link>
                </div>
              </Nav>
            </SideBar>
            {!isExpired && (
              <div className={styles.pageContent}>
                <Routes>
                  <Route index element={<Home />} />
                  <Route path="items/:id" element={<Item />} />
                  <Route path="systems/:id" element={<System />} />
                  <Route path="projects" element={<Projects />} />
                  <Route path="projects/:id" element={<Project />} />
                  <Route path="projects/:id/sharings" element={<ProjectSharings />} />
                  <Route path="configurations" element={<Configurations />} />
                  <Route path="configurations/:id" element={<Configuration />} />
                  <Route path="projects/:projectId/configurations/:id" element={<Configuration />} />
                  {!!auth.permissions.admin.canManageProjects && (
                    <>
                      <Route path="admin/projects" element={<AdminProjects />} />
                      <Route path="admin/projects/:id" element={<Project isAdmin />} />
                      <Route path="admin/projects/:id/sharings" element={<ProjectSharings isAdmin />} />
                      <Route path="admin/documents" element={<AdminDocuments />} />
                      <Route path="admin/configurations" element={<AdminConfigurations />} />
                      <Route path="admin/configurations/:id" element={<Configuration isAdmin />} />
                      <Route path="admin/projects/:projectId/configurations/:id" element={<Configuration isAdmin />} />
                    </>
                  )}
                  {!!auth.permissions.admin.canManageSystems && (
                    <>
                      <Route path="admin/systems" element={<AdminSystems />} />
                      <Route path="admin/systems/new" element={<NewAdminSystem />} />
                      <Route path="admin/systems/:id" element={<EditAdminSystem />} />
                      <Route path="admin/systems/:id/settings" element={<AdminSettings />} />
                      <Route path="admin/systems/:systemId/normatives/:id" element={<Normative />} />
                      <Route path="admin/systems/:systemId/steel_versions/:id" element={<SteelVersion />} />
                      <Route path="admin/systems/:systemId/system_types/:id" element={<SystemType />} />
                      <Route path="admin/systems/:systemId/static_texts/:id" element={<Text />} />
                      <Route path="admin/systems/:systemId/installation_types/:id" element={<InstallationType />} />
                      <Route
                        path="admin/systems/:systemId/installation_types/:installationTypeId/wall_materials/:id"
                        element={<WallMaterial />}
                      />
                      <Route
                        path="admin/systems/:systemId/installation_types/:installationTypeId/wall_materials/:wallMaterialId/fixings/new"
                        element={<NewFixing />}
                      />
                      <Route
                        path="admin/systems/:systemId/installation_types/:installationTypeId/wall_materials/:wallMaterialId/fixings/:id"
                        element={<EditFixing />}
                      />
                    </>
                  )}
                  {!!auth.permissions.admin.canManageUsers && (
                    <>
                      <Route path="admin/users" element={<Users />} />
                      <Route path="admin/users/:id" element={<AdminUser />} />
                    </>
                  )}
                  {!!auth.permissions.admin.canManageItems && (
                    <>
                      <Route path="admin/items" element={<AdminItems />} />
                      <Route path="admin/items/new" element={<NewAdminItem />} />
                      <Route path="admin/items/:id" element={<EditAdminItem />} />
                    </>
                  )}
                  {!!auth.permissions.admin.canManageClients && (
                    <>
                      <Route path="admin/clients" element={<AdminClients />} />
                      <Route path="admin/clients/new" element={<NewAdminClient />} />
                      <Route path="admin/clients/:id" element={<EditAdminClient />} />
                    </>
                  )}
                  <Route path="error" element={<Error />} />
                  <Route
                    path="*"
                    element={
                      <>
                        <Header title={i18n.translation.common.notFound} />
                        <ScrollContent padding>
                          <ErrorText text={i18n.translation.common.resourceNotFound} />
                        </ScrollContent>
                      </>
                    }
                  />
                </Routes>
              </div>
            )}
          </div>
        </>
      ) : undefined}
      {show && <Notification type={severity} text={message} />}
      <ModalDialog
        isOpen={isExpired}
        onClose={() => logout()}
        title={i18n.translation.common.sessionExpired}
        actions={[
          {
            title: i18n.translation.common.close,
            onClick: () => logout(),
          },
        ]}
      >
        {i18n.translation.common.sessionExpiredText}
      </ModalDialog>
      <CookiesBanner />
    </NotificationDispatch.Provider>
  );
};
