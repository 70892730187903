import classNames from "classnames";
import React, { Children, FC, PropsWithChildren, ReactElement } from "react";
import { NavLink, To } from "react-router-dom";
import { Glyph } from "../../types";
import SvgOpenInNew from "../icons/OpenInNew";
import Icon from "./Icon";
import styles from "./Nav.module.css";

const Nav = ({ children }: PropsWithChildren) => {
  return <NavList>{children}</NavList>;
};

const NavList = ({ children }: PropsWithChildren) => <ul className={styles.ul}>{children}</ul>;

type NavGroupProps = PropsWithChildren<{ title: string }>;

const NavGroup = ({ title, children }: NavGroupProps) => {
  return (
    <li className={styles.group}>
      <span className={styles.title}>{title}</span>
      {!!children && <NavList>{children}</NavList>}
    </li>
  );
};

interface NavItemProps {
  text: string;
  icon?: Glyph;
  to: To;
  target?: React.HTMLAttributeAnchorTarget;
  onClick?: () => void;
  children?: ReactElement<NavItemProps>[] | ReactElement<NavItemProps>;
}

const NavItem: FC<NavItemProps> = ({ text, icon, to, target = "_self", onClick, children }: NavItemProps) => {
  return (
    <li className={classNames(styles.li)}>
      <NavLink
        to={to}
        target={target}
        onClick={onClick}
        className={({ isActive }) =>
          classNames(styles.link, {
            [styles.linkActive]: isActive,
          })
        }
      >
        {icon && <Icon glyph={icon} className={styles.linkIcon} />}
        {text}
        {target === "_blank" && <Icon glyph={SvgOpenInNew} className={styles.openInNew} />}
      </NavLink>
      {children && (
        <NavList>
          {Children.map(children, (child) => ({
            ...child,
            props: {
              ...child.props,
              to: `${to}/${child.props.to}`,
            },
          }))}
        </NavList>
      )}
    </li>
  );
};

Nav.Group = NavGroup;
Nav.Item = NavItem;

export default Nav;
