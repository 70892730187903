import { FC } from "react";
import useTabs from "../../../hooks/useTabs";
import useI18n from "../../../hooks/useTranslations";
import { LadstepConfiguration } from "../../../http/types/configurations";
import Header from "../../ui/Header";
import Tabs from "../../ui/Tabs";

interface LadstepProps {
  configuration: LadstepConfiguration;
  isAdmin?: boolean;
}

const Ladstep: FC<LadstepProps> = ({ configuration: data }: LadstepProps) => {
  const i18n = useI18n();
  const controller = useTabs(1);

  return (
    <>
      <Header title={data.system.name} />
      <Tabs controller={controller} tabs={[{ title: i18n.translation.common.generalData }]} panels={[]} />
    </>
  );
};

export default Ladstep;
