import { Glyph } from "../../types";

const SvgExcel: Glyph = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1024 1024" {...props}>
      <path d="M825.6 376.2H697.8v78.3h127.8v-78.3z m0 100.6H697.8v79.9h127.8v-79.9z m0-201.3H697.8v78.3h127.8v-78.3z m0 397.7H697.8v79.9h127.8v-79.9z m59.1-477.5H578.1c-3.2 0-6.4 0-9.6 1.6v-84.6l-455.2 91v618.1l453.6 89.4v-81.4c3.2 1.6 6.4 1.6 9.6 1.6h306.6c16 0 27.2-12.8 27.2-28.7V224.4c1.5-15.9-11.2-28.7-25.6-28.7zM420 644.5l-54.3-3.2-41.5-103.8-44.7 97.4-51.1-3.2 65.5-118.2-59.1-129.4 51.1-3.2 35.1 97.4h6.4l38.3-103.8 54.3-3.2-68.7 137.3L420 644.5z m456.7 151.7H566.9v-43.1h103.8v-79.9H566.9v-17.6h103.8v-78.3H566.9v-20.8h103.8v-79.9H566.9v-22.4h103.8v-78.3H566.9v-20.8h103.8v-78.3H566.9v-44.7h309.8v564.1z m-51.1-218.8H697.8v78.3h127.8v-78.3z m0 0" />
    </svg>
  );
};

export default SvgExcel;
