import { GoogleMap, Libraries, StandaloneSearchBox, useJsApiLoader } from "@react-google-maps/api";

import { FC, useEffect, useMemo, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import ReactSelect from "react-select";
import { useAuth } from "../../context/useAuthContext";
import useI18n from "../../hooks/useTranslations";
import { getProjectStatusValues, Project, ProjectStatus } from "../../http/types/projects";
import { getUILanguages, Language } from "../../types";
import SimpleTextModalDialog from "../configurations/vertigrip/SimpleTextModalDialog";
import ErrorText from "../ui/ErrorText";
import Form from "../ui/Form";
import FormField from "../ui/FormField";
import Input from "../ui/Input";
import reactSelectStyles from "../ui/ReactSelectUtils";
import Textarea from "../ui/Textarea";
import styles from "./ProjectDetails.module.css";

interface ProjectDetailsProps {
  project?: Project;
  onSubmit: (body: ProjectDetailsFormBody) => Promise<void>;
  disabled?: boolean;
  isLoading?: boolean;
  isAdmin?: boolean;
}

export interface ProjectDetailsFormBody {
  name?: string;
  language?: Language;
  status?: ProjectStatus;
  description?: string;
  address?: string;
  coordinates?: string;
  company?: string;
  contactPerson?: string;
}
const latLongRegex = /^(?:[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?((1[0-7]\d(\.\d+)?|180(\.0+)?|\d{1,2}(\.\d+)?))|)$/;

const zoomNoCoordinates = 16;
const zoomCoordinates = 18;

const ProjectDetails: FC<ProjectDetailsProps> = ({
  project,
  onSubmit,
  disabled,
  isLoading,
  isAdmin,
}: ProjectDetailsProps) => {
  const i18n = useI18n();
  const auth = useAuth();
  const searchBox = useRef<google.maps.places.SearchBox | null>(null);
  const [isPageLoading, setIsPageLoading] = useState(true);
  const [coordinates, setCoordinates] = useState<string | undefined>(project?.coordinates);
  const [mapError, setMapError] = useState<boolean>(false);
  const map = useRef<google.maps.Map>();
  const geocoder = useRef<google.maps.Geocoder>();
  const currentMarker = useRef<google.maps.marker.AdvancedMarkerElement>();
  const [showContactPersonDialog, setShowContactPersonDialog] = useState(false);

  const languages = getUILanguages(i18n.translation);

  const libraries = useMemo<Libraries>(() => ["places"], []);

  const stati = getProjectStatusValues(i18n.translation);

  const onMapLoad = (mapInstance: google.maps.Map) => {
    map.current = mapInstance;
    geocoder.current = new google.maps.Geocoder();

    coordinates
      ? mapInstance.moveCamera({
          center: new google.maps.LatLng({
            lat: Number(coordinates.split(",")[0]),
            lng: Number(coordinates.split(",")[1]),
          }),
        })
      : navigator?.geolocation.getCurrentPosition(({ coords: { latitude: lat, longitude: lng } }) => {
          mapInstance.moveCamera({
            center: new google.maps.LatLng({
              lat,
              lng,
            }),
          });
        });
  };

  const onPlacesChanged = () => {
    const places = searchBox.current?.getPlaces();
    if (places && places.length > 0) {
      const place = places[0];
      const location = place?.geometry?.location;
      if (location) {
        setCoordinates(`${location?.lat().toFixed(7)},${location?.lng().toFixed(7)}`);
        map.current?.moveCamera({ center: location });
      }
    }
  };

  const onDblClick = (e: google.maps.MapMouseEvent) => {
    e.stop();
    if (e.latLng) {
      setCoordinates(`${e.latLng.lat().toFixed(7)},${e.latLng.lng().toFixed(7)}`);
      // const currentZoom = map.current?.getZoom();
      // if (currentZoom && currentZoom < zoomCoordinates) {
      // map.current?.setZoom(zoomCoordinates);
      // map.current?.moveCamera({ center: e.latLng });
      // }
    }
  };

  const { isLoaded: isMapLoaded, loadError: mapLoadError } = useJsApiLoader({
    googleMapsApiKey: import.meta.env.VITE_GOOGLE_MAPS_API_KEY,
    libraries: libraries,
    language: auth.identity?.language,
  });

  useEffect(() => {
    setMapError(!!mapLoadError);
    setIsPageLoading(!isMapLoaded);
  }, [mapLoadError, isMapLoaded]);

  const updateMarker = async (latLng?: google.maps.LatLng) => {
    const { AdvancedMarkerElement } = (await google.maps.importLibrary("marker")) as google.maps.MarkerLibrary;
    if (currentMarker.current) {
      currentMarker.current.map = undefined;
    }

    if (latLng) {
      currentMarker.current = new AdvancedMarkerElement({
        map: map.current,
        position: latLng,
      });
    }
  };

  const defaultValues: ProjectDetailsFormBody = {
    name: project?.name,
    address: project?.address,
    coordinates: project?.coordinates,
    description: project?.description,
    language: languages.find((x) => x.value === project?.language),
    status: stati.find((x) => x.value === project?.status),
    company: project?.company,
    contactPerson: project?.contactPerson,
  };

  return (
    <div className={styles.container}>
      <div className={styles.form}>
        <Form<ProjectDetailsFormBody>
          defaultValues={defaultValues}
          mode="onBlur"
          disabled={disabled}
          onSubmit={isAdmin ? () => (formData) => onSubmit(formData) : undefined}
          submitText={i18n.translation.common.save}
        >
          {({ register, reset, formState: { errors }, control, setValue, trigger, handleSubmit }) => {
            useEffect(() => {
              reset(defaultValues);
              setCoordinates(defaultValues.coordinates);
            }, [defaultValues.name, defaultValues.language, defaultValues.status]);

            const submit = () => {
              !isAdmin &&
                trigger().then((x) => {
                  x &&
                    handleSubmit((data) => {
                      onSubmit(data).then(() => {
                        reset(data);
                      });
                    })();
                });
            };

            const updateAddress = (latLng: google.maps.LatLng) => {
              if (geocoder.current) {
                const timeout = setTimeout(() => {
                  setIsPageLoading(true);
                }, 200);

                geocoder.current.geocode({ location: latLng }, (results, status) => {
                  if (status === google.maps.GeocoderStatus.OK && results) {
                    const address = results[0]?.formatted_address;
                    setValue("address", address);
                  }
                  clearTimeout(timeout);
                  setIsPageLoading(false);
                  setMapError(
                    status !== google.maps.GeocoderStatus.OK && status !== google.maps.GeocoderStatus.ZERO_RESULTS
                  );
                  setValue("coordinates", coordinates);
                  submit();
                });
              }
            };

            useEffect(() => {
              if (isMapLoaded && coordinates) {
                const latLng = new google.maps.LatLng({
                  lat: Number(coordinates.split(",")[0]),
                  lng: Number(coordinates.split(",")[1]),
                });

                updateMarker(latLng);
                updateAddress(latLng);
              }
            }, [coordinates, isMapLoaded]);

            return (
              <>
                <FormField label={i18n.translation.common.name} error={errors.name}>
                  {({ labelId, isOptional, isInvalid }) => (
                    <Input
                      id={labelId}
                      isInvalid={isInvalid}
                      disabled={disabled || isLoading}
                      {...register("name", {
                        required: !isOptional,
                        minLength: 1,
                      })}
                      onBlur={(e) => {
                        setValue("name", e.target.value);
                        defaultValues.name !== e.target.value && submit();
                      }}
                    />
                  )}
                </FormField>
                <Controller
                  name="language"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={defaultValues.language}
                  render={({ field, fieldState }) => (
                    <FormField label={i18n.translation.common.languages.singular} error={fieldState.error}>
                      {({ labelId }) => (
                        <ReactSelect
                          {...field}
                          inputId={labelId}
                          options={languages}
                          placeholder={`${i18n.translation.common.select}...`}
                          isDisabled={!isMapLoaded || disabled || isLoading}
                          isLoading={!isMapLoaded || isLoading}
                          getOptionLabel={(x) => x.display}
                          styles={reactSelectStyles<Language>()}
                          onChange={(value) => {
                            setValue("language", value ?? undefined);
                            defaultValues.language?.value !== value?.value && submit();
                          }}
                        />
                      )}
                    </FormField>
                  )}
                />
                <Controller
                  name="status"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={defaultValues.status}
                  render={({ field, fieldState }) => (
                    <FormField label={i18n.translation.common.status} error={fieldState.error}>
                      {({ labelId }) => (
                        <ReactSelect
                          {...field}
                          inputId={labelId}
                          options={stati}
                          placeholder={`${i18n.translation.common.select}...`}
                          isDisabled={disabled || isLoading}
                          isLoading={isLoading}
                          getOptionLabel={(x) => x.display}
                          styles={reactSelectStyles<ProjectStatus>()}
                          onChange={(value) => {
                            setValue("status", value ?? undefined);
                            defaultValues.language?.value !== value?.value && submit();
                          }}
                        />
                      )}
                    </FormField>
                  )}
                />
                <FormField label={i18n.translation.common.address} error={errors.address} isOptional>
                  {({ labelId, isOptional, isInvalid }) =>
                    isMapLoaded ? (
                      <div className={styles.standAloneSearchBox}>
                        <StandaloneSearchBox
                          onLoad={(ref) => (searchBox.current = ref)}
                          onPlacesChanged={onPlacesChanged}
                        >
                          <Input
                            id={labelId}
                            // placeholder={disabled ? "" : i18n.translation.common.search}
                            placeholder=""
                            isInvalid={isInvalid}
                            disabled={disabled || isLoading}
                            {...register("address", {
                              required: !isOptional,
                            })}
                            onBlur={(e) => {
                              if (!e.target.value) {
                                setCoordinates(undefined);
                                setValue("coordinates", undefined);
                                updateMarker();
                              }
                              setValue("address", e.target.value);
                              defaultValues?.address !== e.target.value && submit();
                            }}
                          />
                        </StandaloneSearchBox>
                      </div>
                    ) : (
                      <Input id={labelId} isInvalid={isInvalid} disabled />
                    )
                  }
                </FormField>
                <FormField label={i18n.translation.common.coordinates} error={errors.coordinates} isOptional hidden>
                  {({ labelId, isOptional, isInvalid }) => (
                    <Input
                      id={labelId}
                      isInvalid={isInvalid}
                      disabled={disabled || isLoading}
                      {...register("coordinates", {
                        required: !isOptional,
                        pattern: latLongRegex,
                        onBlur(e) {
                          !e.target.value && setCoordinates(undefined);

                          const regexp = new RegExp(latLongRegex);
                          const valid = regexp.test(e.target.value);

                          // if no coordinates are selected, remove the marker from the map and remove the address from the form
                          if (!e.target.value) {
                            setValue("address", "");
                            map.current?.setZoom(zoomNoCoordinates);
                            updateMarker();
                          }

                          // if no coordinates are selected or they are invalid, set the form value and return in order to not reload the address
                          if (!e.target.value || !valid) {
                            setValue("coordinates", e.target.value);
                            submit();
                            return;
                          }

                          // if the coordinates change set them in order to trigger the address/place search
                          if (coordinates != e.target.value) {
                            setCoordinates(e.target.value);
                          }
                        },
                      })}
                    />
                  )}
                </FormField>
                <FormField label={i18n.translation.common.company} error={errors.company} isOptional>
                  {({ labelId, isOptional, isInvalid }) => (
                    <Input
                      id={labelId}
                      isInvalid={isInvalid}
                      disabled={disabled || isLoading}
                      {...register("company", {
                        required: !isOptional,
                        minLength: 1,
                      })}
                      onBlur={(e) => {
                        setValue("company", e.target.value);
                        defaultValues.company !== e.target.value && submit();
                      }}
                    />
                  )}
                </FormField>
                <FormField
                  label={i18n.translation.common.contactPerson}
                  error={errors.contactPerson}
                  isOptional
                  // showHelp={{
                  //   type: "error",
                  //   onOpen: () => setShowContactPersonDialog(true),
                  // }}
                >
                  {({ labelId, isOptional, isInvalid }) => (
                    <>
                      <Input
                        id={labelId}
                        isInvalid={isInvalid}
                        disabled={disabled || isLoading}
                        {...register("contactPerson", {
                          required: !isOptional,
                          minLength: 1,
                        })}
                        onBlur={(e) => {
                          setValue("contactPerson", e.target.value);
                          defaultValues.contactPerson !== e.target.value && submit();
                        }}
                      />
                      <SimpleTextModalDialog
                        title={i18n.translation.common.contactPerson}
                        text={{ value: "TODO" }}
                        isOpen={showContactPersonDialog}
                        onClose={() => setShowContactPersonDialog(false)}
                      />
                    </>
                  )}
                </FormField>
                <FormField label={i18n.translation.common.description} error={errors.description} isOptional>
                  {({ labelId, isOptional, isInvalid }) => (
                    <Textarea
                      id={labelId}
                      isInvalid={isInvalid}
                      disabled={disabled || isLoading}
                      rows={5}
                      {...register("description", { required: !isOptional })}
                      onBlur={(e) => {
                        setValue("description", e.target.value);
                        defaultValues.description !== e.target.value && submit();
                      }}
                    />
                  )}
                </FormField>
              </>
            );
          }}
        </Form>
      </div>
      <div className={styles.gmapsContainer}>
        {(isPageLoading || mapError || disabled || isLoading) && (
          <div className={styles.gmapsOverlay}>
            {isPageLoading && <div className={styles.spinner}></div>}
            {mapError && <ErrorText text={i18n.translation.common.error} />}
          </div>
        )}
        {isMapLoaded && !mapError && (
          <GoogleMap
            mapContainerStyle={{
              width: "100%",
              height: "100%",
              outline: "none",
            }}
            zoom={coordinates ? zoomCoordinates : zoomNoCoordinates}
            onLoad={onMapLoad}
            options={{
              disableDoubleClickZoom: true,
              mapTypeId: "satellite",
              mapId: "PROJECT_MAP_ID",
            }}
            onDblClick={onDblClick}
          />
        )}
      </div>
    </div>
  );
};

export default ProjectDetails;
