import { FC, useRef } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../context/useAuthContext";
import useOnClickOutside from "../hooks/useOnClickOutside";
import useToggle from "../hooks/useToggle";
import useI18n from "../hooks/useTranslations";
import styles from "./AccountButton.module.css";
import SvgLogout from "./icons/Logout";
import SvgOpenInNew from "./icons/OpenInNew";
import SvgPerson from "./icons/Person";
import Icon from "./ui/Icon";
import Profile from "./ui/Profile";

const AccountButton: FC = () => {
  const ref = useRef<HTMLDivElement>(null);
  const menu = useToggle();
  const { identity, logout } = useAuth();
  const i18n = useI18n();

  useOnClickOutside(ref, menu.close);

  return (
    !!identity && (
      <div ref={ref} className={styles.wrapper}>
        <button className={styles.button} onClick={menu.toggle}>
          <Profile identity={identity} layout="compact" />
        </button>
        {menu.isOpen && (
          <div className={styles.menu}>
            <Profile identity={identity} className={styles.profile} layout="full" />
            <hr className={styles.separator} />
            <ul className={styles.list}>
              <li>
                <Link
                  to={import.meta.env.VITE_IAM_URL}
                  target="_blank"
                  className={styles.menuItem}
                  onClick={menu.close}
                >
                  <Icon glyph={SvgPerson} className={styles.icon} />
                  {i18n.translation.common.myProfile}
                  <Icon glyph={SvgOpenInNew} className={styles.openInNew} />
                </Link>
              </li>
              <li>
                <button className={styles.menuItem} onClick={logout}>
                  <Icon glyph={SvgLogout} className={styles.icon} />
                  {i18n.translation.common.logout}
                </button>
              </li>
            </ul>
          </div>
        )}
      </div>
    )
  );
};

export default AccountButton;
