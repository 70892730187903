import { FC, useContext, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import NotificationDispatch, { showErrorNotification } from "../../context/notificationContext";
import { useDebounce } from "../../hooks/useDebounce";
import useInitializer from "../../hooks/useInitializer";
import useListFilters from "../../hooks/useListFilters";
import useI18n from "../../hooks/useTranslations";
import { getProjectStatusValues, SearchAdminProject } from "../../http/types/projects";
import { useProjectsApi } from "../../http/useProjects";
import { PaginationQuery } from "../../types";
import { formatDate, getProjectCountryAndRest } from "../../utils";
import Header from "../ui/Header";
import ModalDialog from "../ui/ModalDialog";
import ScrollContent from "../ui/ScrollContent";
import Table from "../ui/Table";
import CreateProjectButton from "./CreateProjectButton";

const AdminProjects: FC = () => {
  const i18n = useI18n();
  const navigate = useNavigate();
  const dispatch = useContext(NotificationDispatch);
  const filters = useListFilters({ isSearchable: true });
  const aborter = useRef(new AbortController());
  const [projectToDelete, setProjectToDelete] = useState<SearchAdminProject>();
  const projectsApi = useProjectsApi();

  const debouncedValue = useDebounce(filters.state.q);

  const stati = getProjectStatusValues(i18n.translation);

  const query: PaginationQuery = {
    ...filters.state,
    q: debouncedValue,
  };

  const { data, error } = projectsApi.getAdminProjects(query, aborter.current.signal);

  const isInitialized = useInitializer(data || error);

  if (!data && !error && !isInitialized) {
    return;
  }

  return (
    <>
      <Header
        title={i18n.translation.projects.plural}
        error={error}
        path={{ display: i18n.translation.projects.all }}
        buttons={<CreateProjectButton onSubmit={(id) => navigate(`/admin/projects/${id}`)} />}
      />

      <ScrollContent padding>
        <Table<SearchAdminProject>
          filters={filters}
          isTall={true}
          searchOptions={{
            placeholder: i18n.translation.projects.search,
            onChange: () => {
              aborter.current.abort();
              aborter.current = new AbortController();
            },
          }}
          head={
            <tr>
              <th align="left">{i18n.translation.common.name}</th>
              <th align="left">{i18n.translation.common.status}</th>
              <th align="left">{i18n.translation.common.location}</th>
              <th align="left">{i18n.translation.common.country}</th>
              <th align="left">{i18n.translation.common.author}</th>
              <th align="left">{i18n.translation.common.created}</th>
              <th align="left">{i18n.translation.common.editor}</th>
              <th align="left">{i18n.translation.common.lastModified}</th>
              <th></th>
            </tr>
          }
          items={data?.items}
          renderRow={(item) => {
            const data = getProjectCountryAndRest(item.address);
            return (
              <>
                <td>
                  <Link to={`/admin/projects/${item.id}?tab=1`}>{item.name}</Link>
                </td>
                <td>{stati.find((x) => x.value === item.status)?.display}</td>
                <td>{data?.location}</td>
                <td>{data?.country}</td>
                <td>{item.author?.name}</td>
                <td>{formatDate(item.createdAt)}</td>
                <td>{item.editor?.name}</td>
                <td>{formatDate(item.modifiedAt)}</td>
              </>
            );
          }}
          rowActions={[
            {
              title: i18n.translation.common.edit,
              onClick: (item) => navigate(`/projects/${item.id}`),
            },
            {
              title: i18n.translation.projects.editSharings,
              onClick: (item) => navigate(`/admin/projects/${item.id}/sharings`),
            },
            { title: i18n.translation.common.delete, onClick: (item) => setProjectToDelete(item) },
          ]}
          total={data?.total}
          error={!!error}
        />
        <ModalDialog
          isOpen={!!projectToDelete}
          onClose={() => setProjectToDelete(undefined)}
          title={i18n.translation.projects.delete}
          actions={[
            {
              title: i18n.translation.common.cancel,
              onClick: () => setProjectToDelete(undefined),
            },
            {
              title: i18n.translation.common.yes,
              onClick: () => {
                projectToDelete &&
                  projectsApi
                    .deleteProject(projectToDelete.id)
                    .then(() => setProjectToDelete(undefined))
                    .catch((err) => dispatch(showErrorNotification(i18n, err)));
              },
            },
          ]}
        >
          {i18n.translation.projects.questions.delete}
        </ModalDialog>
      </ScrollContent>
    </>
  );
};

export default AdminProjects;
