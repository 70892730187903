import { FC, useRef } from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../../context/useAuthContext";
import { useDebounce } from "../../hooks/useDebounce";
import useInitializer from "../../hooks/useInitializer";
import useListFilters from "../../hooks/useListFilters";
import useI18n from "../../hooks/useTranslations";
import { SearchUser } from "../../http/types/users";
import { useUsersApi } from "../../http/useUsers";
import { PaginationQuery } from "../../types";
import { formatBoolean, formatDate } from "../../utils";
import Header from "../ui/Header";
import ScrollContent from "../ui/ScrollContent";
import Table from "../ui/Table";

const Users: FC = () => {
  const i18n = useI18n();
  const auth = useAuth();
  const filters = useListFilters({ isSearchable: true });
  const aborter = useRef(new AbortController());

  const debouncedValue = useDebounce(filters.state.q);

  const usersApi = useUsersApi();

  const query: PaginationQuery = {
    ...filters.state,
    q: debouncedValue,
  };

  const { data, error } = usersApi.getAdminUsers(query, aborter.current.signal);

  const isInitialized = useInitializer(data || error);

  if (!data && !error && !isInitialized) {
    return;
  }

  return (
    <>
      <Header title={i18n.translation.users.plural} path={{ display: i18n.translation.users.all }} error={error} />

      <ScrollContent padding>
        <Table<SearchUser>
          filters={filters}
          isTall={true}
          searchOptions={{
            placeholder: i18n.translation.users.search,
            onChange: () => {
              aborter.current.abort();
              aborter.current = new AbortController();
            },
          }}
          head={
            <tr>
              <th align="left">{i18n.translation.common.email}</th>
              <th align="left">{i18n.translation.common.name}</th>
              <th align="left">{i18n.translation.common.lastAccess}</th>
              <th align="left">{i18n.translation.common.active}</th>
            </tr>
          }
          items={data?.items}
          renderRow={(item) => (
            <>
              <td>
                <Link to={item.id}>{item.email}</Link>
              </td>
              <td>{item.name}</td>
              <td>{!!item.lastAccess && formatDate(item.lastAccess, auth.identity?.language)}</td>
              <td>{formatBoolean(i18n.translation, item.isActive)}</td>
            </>
          )}
          total={data?.total}
          error={!!error}
        />
      </ScrollContent>
    </>
  );
};

export default Users;
