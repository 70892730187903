import { FC } from "react";
import styles from "./MultiCheckbox.module.css";

interface MultiCheckboxProps {
  values: {
    id: string;
    title: string;
    selected?: boolean;
    disabled?: boolean;
  }[];
  onUpdate: (id: string, checked: boolean) => void;
  disabled?: boolean;
}

const MultiCheckbox: FC<MultiCheckboxProps> = ({ values, disabled, onUpdate }: MultiCheckboxProps) => {
  return (
    <div className={styles.multiCheckBox}>
      <div>
        {values
          .sort((a, b) => a.title.localeCompare(b.title))
          .map((x) => (
            <div className={styles.multiCheckBoxItem} key={`cb_${x.id}`}>
              <div className={styles.multiCheckBoxLabel}>
                <label htmlFor={x.id}>{x.title}</label>
              </div>
              <input
                type={"checkbox"}
                id={x.id}
                value={x.id}
                checked={x.selected}
                disabled={x.disabled || disabled}
                onChange={(e) => onUpdate(x.id, e.target.checked)}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default MultiCheckbox;
