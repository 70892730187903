import { useCallback } from "react";
import { default as useSWRImmutable } from "swr/immutable";
import useSWRMutation from "swr/mutation";
import { Paginated, PaginationQuery, PostPolicy } from "../types";
import { buildUrl, mutateCache, useApi } from "./api";
import { CustomError } from "./errors";
import {
  AdminSystem,
  SearchAdminSystem,
  SearchSystem,
  System,
  UpsertSystemNewImageBody,
  UpsertSystemRemoveImageBody,
} from "./types/systems";

export const useSystemsApi = () => {
  const api = useApi();

  const useSystems = useCallback(
    (signal?: AbortSignal) => {
      const url = `/systems`;
      return useSWRImmutable<SearchSystem[], CustomError, string>(url, (url) =>
        api.get<SearchSystem[]>({ url, signal })
      );
    },
    [api]
  );

  const getSystems = useCallback(
    (signal: AbortSignal) => {
      const url = `/systems`;
      return useSWRMutation<System[], CustomError, string>(url, (url) => api.get<System[]>({ url, signal }));
    },
    [api]
  );

  const getSystem = useCallback(
    (id: string, signal?: AbortSignal) => {
      return useSWRImmutable<System, CustomError, string>(`/systems/${id}`, (url) => api.get<System>({ url, signal }));
    },
    [api]
  );

  const getAdminSystems = useCallback(
    (query: PaginationQuery, signal: AbortSignal) => {
      const url = buildUrl(`/admin/systems`, query);
      return useSWRImmutable<Paginated<SearchAdminSystem>, CustomError, string>(url, (url) =>
        api.get<Paginated<SearchAdminSystem>>({ url, signal })
      );
    },
    [api]
  );

  const getAdminSystem = useCallback(
    (id: string, signal: AbortSignal) => {
      return useSWRImmutable<AdminSystem, CustomError, string>(`/admin/systems/${id}`, (url) =>
        api.get<AdminSystem>({ url, signal })
      );
    },
    [api]
  );

  const insertAdminSystem = useCallback(
    async (body: UpsertSystemRemoveImageBody | UpsertSystemNewImageBody): Promise<{ id: string }> => {
      const url = `/admin/systems`;
      const id = await api.post<{ id: string }, UpsertSystemRemoveImageBody | UpsertSystemNewImageBody>(url, body);
      mutateCache(["/systems", url]);
      return id;
    },
    [api]
  );

  const updateAdminSystem = useCallback(
    async (systemId: string, body: UpsertSystemRemoveImageBody | UpsertSystemNewImageBody): Promise<void> => {
      const url = `/admin/systems/${systemId}`;
      await api.put<UpsertSystemRemoveImageBody | UpsertSystemNewImageBody>(url, body);
      mutateCache(["/systems", `/systems/${systemId}`, "/admin/systems"], url);
    },
    [api]
  );

  const deleteAdminSystem = useCallback(
    async (systemId: string): Promise<void> => {
      await api.del(`/admin/systems/${systemId}`).then(() => {
        mutateCache(["/systems", `/systems/${systemId}`, "/admin/systems"]);
      });
    },
    [api]
  );

  const generateUploadImageUrl = useCallback(
    async (systemId: string, fileName: string): Promise<PostPolicy> => {
      const encodedFileName = encodeURIComponent(fileName);
      const url = `/admin/systems/${systemId}/image/generate_upload_url?file_name=${encodedFileName}`;
      return api.get<PostPolicy>({ url });
    },
    [api]
  );

  return {
    useSystems,
    getSystems,
    getSystem,
    getAdminSystems,
    getAdminSystem,
    insertAdminSystem,
    updateAdminSystem,
    deleteAdminSystem,
    generateUploadImageUrl,
  };
};
