import { FC, useContext, useEffect, useRef, useState } from "react";
import { Controller } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import NotificationDispatch, {
  showErrorNotification,
  showSuccessNotification,
} from "../../context/notificationContext";
import { useAuth } from "../../context/useAuthContext";
import useTabs from "../../hooks/useTabs";
import useI18n from "../../hooks/useTranslations";
import { useApi } from "../../http/api";
import { isNotFound } from "../../http/errors";
import {
  AdminItem,
  InsertAutomaticSyncItemsBody,
  InsertManualItemsBody,
  UpdateAutomaticSyncItemsNewImageBody,
  UpdateAutomaticSyncItemsRemoveImageBody,
  UpdateManualItemsNewImageBody,
  UpdateManualItemsRemoveImageBody,
} from "../../http/types/items";
import { useItemsApi } from "../../http/useItems";
import en from "../../i18n/en";
import { getUILanguages } from "../../types";
import { formatTimeStamp } from "../../utils";
import Button from "../ui/Button";
import Form from "../ui/Form";
import FormField from "../ui/FormField";
import FormFieldsContainer from "../ui/FormFieldsContainer";
import Header from "../ui/Header";
import useImageUploader from "../ui/ImageUploader";
import Input from "../ui/Input";
import Numeric from "../ui/Numeric";
import ScrollContent from "../ui/ScrollContent";
import Switch from "../ui/Switch";
import Tabs from "../ui/Tabs";
import Textarea from "../ui/Textarea";

export const NewAdminItem: FC = () => {
  const i18n = useI18n();

  return (
    <>
      <Header
        title={i18n.translation.items.createNew}
        path={[
          { display: i18n.translation.items.all, url: "/admin/items" },
          { display: i18n.translation.items.createNew },
        ]}
      />

      <NewEditAdminItem />
    </>
  );
};

export const EditAdminItem: FC = () => {
  const i18n = useI18n();
  const { id } = useParams<{ id: string }>();
  const itemsApi = useItemsApi();

  const aborter = useRef(new AbortController());

  const { error, data, isMutating, trigger } = itemsApi.getAdminItem(id!, aborter.current.signal);

  useEffect(() => {
    id && trigger();
  }, [id]);

  return (
    <>
      <Header
        title={data?.code ?? i18n.translation.common.loading}
        error={error}
        path={[{ display: i18n.translation.items.all, url: "/admin/items" }, { display: data?.code! }]}
      />

      <NewEditAdminItem item={!error ? data : undefined} disabled={isMutating || !!error} onUpdate={trigger} />
    </>
  );
};

interface NewEditAdminItemProps {
  item?: AdminItem;
  disabled?: boolean;
  onUpdate?: () => void;
}

interface ItemFormBody {
  code: string | null;
  name: string | null;
  nameIt: string | null;
  nameDe: string | null;
  nameEs: string | null;
  nameFr: string | null;
  description: string | null;
  descriptionIt: string | null;
  descriptionDe: string | null;
  descriptionEs: string | null;
  descriptionFr: string | null;
  automaticSync: boolean;
  minConfigurableQuantity: number | null;
}

const NewEditAdminItem: FC<NewEditAdminItemProps> = ({ item, disabled, onUpdate }: NewEditAdminItemProps) => {
  const i18n = useI18n();
  const auth = useAuth();
  const api = useApi();
  const itemsApi = useItemsApi();
  const dispatch = useContext(NotificationDispatch);
  const navigate = useNavigate();

  const languages = getUILanguages(i18n.translation);
  const controller = useTabs(languages.length, "l_tab");
  const [tabs, setTabs] = useState(languages.map((x) => ({ title: x.display, value: x.value })));

  const [isDirty, setIsDirty] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { component, newImage, removeImage, onSave } = useImageUploader({
    defaultImage: item?.image,
    disabled: disabled || controller.selectedIndex != 0,
  });

  useEffect(() => {
    setTabs(languages.map((x) => ({ title: x.display, value: x.value })));
  }, [i18n.translation]);

  const defaultValues: ItemFormBody = {
    code: item?.code ?? null,
    name: item?.name ?? null,
    nameIt: item?.nameIt ?? null,
    nameDe: item?.nameDe ?? null,
    nameEs: item?.nameEs ?? null,
    nameFr: item?.nameFr ?? null,
    description: item?.description ?? null,
    descriptionIt: item?.descriptionIt ?? null,
    descriptionDe: item?.descriptionDe ?? null,
    descriptionEs: item?.descriptionEs ?? null,
    descriptionFr: item?.descriptionFr ?? null,
    automaticSync: item?.automaticSync ?? false,
    minConfigurableQuantity: item?.minConfigurableQuantity ?? 1,
  };

  const onSubmit = () => (formData: ItemFormBody) => {
    const commonBody = {
      code: formData.code?.trim()!,
      name: formData.name?.trim() ?? null,
      nameIt: formData.nameIt?.trim() ?? null,
      nameDe: formData.nameDe?.trim() ?? null,
      nameEs: formData.nameEs?.trim() ?? null,
      nameFr: formData.nameFr?.trim() ?? null,
      description: formData.description!,
      descriptionIt: formData.descriptionIt?.trim() ?? null,
      descriptionDe: formData.descriptionDe?.trim() ?? null,
      descriptionEs: formData.descriptionEs?.trim() ?? null,
      descriptionFr: formData.descriptionFr?.trim() ?? null,
      minConfigurableQuantity: formData.minConfigurableQuantity,
    };

    setIsSubmitting(true);
    if (item?.id) {
      const submit = (
        body:
          | UpdateManualItemsRemoveImageBody
          | UpdateManualItemsNewImageBody
          | UpdateAutomaticSyncItemsRemoveImageBody
          | UpdateAutomaticSyncItemsNewImageBody
      ) =>
        itemsApi
          .updateAdminItem(item.id, body)
          .then(() => {
            dispatch(showSuccessNotification(i18n.translation));
            setIsDirty(false);
            onSave();
          })
          .catch((err) => dispatch(showErrorNotification(i18n, err)))
          .finally(() => setIsSubmitting(false));

      if (newImage) {
        itemsApi
          .generateUploadImageUrl(item?.id!, newImage.name)
          .then((uploadPolicy) => {
            api.uploadFile(uploadPolicy, newImage).then((res) => {
              if (!res.ok) {
                dispatch(showErrorNotification(i18n, res));
                return;
              }
              const body: UpdateAutomaticSyncItemsNewImageBody | UpdateManualItemsNewImageBody = formData.automaticSync
                ? {
                    code: formData.code!,
                    image: newImage.name,
                    automaticSync: true,
                  }
                : {
                    ...commonBody,
                    image: newImage.name,
                    automaticSync: false,
                  };
              submit(body);
            });
          })
          .catch((err) => {
            dispatch(showErrorNotification(i18n, err));
            setIsSubmitting(false);
          })
          .finally(() => setIsSubmitting(false));
      } else {
        const body: UpdateAutomaticSyncItemsRemoveImageBody | UpdateManualItemsRemoveImageBody = formData.automaticSync
          ? {
              code: formData.code?.trim()!,
              automaticSync: true,
              removeImage: removeImage,
            }
          : {
              ...commonBody,
              automaticSync: false,
              removeImage: removeImage,
            };
        submit(body);
      }
    } else {
      const submit = (body: InsertManualItemsBody | InsertAutomaticSyncItemsBody) =>
        itemsApi
          .insertAdminItem(body)
          .then((res) => navigate(`/admin/items/${res.id}`, { replace: true }))
          .catch((err) => dispatch(showErrorNotification(i18n, err)));

      if (formData.automaticSync) {
        const body: InsertAutomaticSyncItemsBody = {
          code: formData.code?.trim()!,
          automaticSync: true,
        };
        submit(body);
      } else {
        const body: InsertManualItemsBody = {
          ...commonBody,
          automaticSync: false,
        };
        submit(body);
      }
    }
  };

  const panel = (
    <ScrollContent direction="row">
      <Form<ItemFormBody>
        submitText={i18n.translation.common.save}
        disabled={disabled || isSubmitting}
        defaultValues={defaultValues}
        secondaryButton={
          <Button
            buttonProps={{
              disabled: isDirty || disabled || isSubmitting,
              onClick: () => {
                if (item?.id) {
                  setIsSubmitting(true);
                  itemsApi
                    .syncAdminItem(item.id)
                    .then(() => {
                      onUpdate?.();
                    })
                    .catch((err) => {
                      const msg = isNotFound(err) ? i18n.translation.items.errors.doesNotExist : undefined;
                      dispatch(showErrorNotification(i18n, err, msg));
                    })
                    .finally(() => setIsSubmitting(false));
                }
              },
            }}
            type="secondary"
          >
            {i18n.translation.common.sync}
          </Button>
        }
        onSubmit={onSubmit}
      >
        {({ register, formState: { errors }, setValue, watch, reset, control }) => {
          useEffect(() => {
            reset(defaultValues);
          }, [item]);

          useEffect(() => {
            setTabs((prevState) =>
              prevState.map((x) => {
                return {
                  ...x,
                  hasError: x.value === en.languageCode && (errors.code || errors.name || errors.description),
                };
              })
            );
          }, [errors.code, errors.name, errors.description]);

          const automaticSync = !!watch("automaticSync");
          return (
            <FormFieldsContainer imageUploader={component} tabbed>
              <FormField
                label={i18n.translation.common.code}
                error={controller.selectedIndex == 0 ? errors.code : undefined}
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("code", {
                      required: !isOptional,
                      minLength: 1,
                    }),
                  };
                  return (
                    <Input
                      id={labelId}
                      isInvalid={isInvalid}
                      disabled={disabled || controller.selectedIndex != 0}
                      forceUpper
                      {...options}
                      onBlur={(e) => {
                        e.target.value !== item?.code && setIsDirty(true);
                        return options.onBlur?.(e);
                      }}
                    />
                  );
                }}
              </FormField>
              <FormField
                label={i18n.translation.common.name}
                error={!automaticSync ? errors.name : undefined}
                hidden={controller.selectedIndex != 0}
                isOptional
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("name", {
                      required: !isOptional,
                      minLength: 1,
                    }),
                  };
                  return (
                    <Input
                      id={labelId}
                      isInvalid={isInvalid}
                      disabled={disabled}
                      {...options}
                      onBlur={(e) => {
                        e.target.value !== item?.name && setIsDirty(true);
                        return options.onBlur?.(e);
                      }}
                    />
                  );
                }}
              </FormField>
              <FormField
                label={i18n.translation.common.name}
                error={errors.nameIt}
                hidden={controller.selectedIndex != 1}
                isOptional
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("nameIt", {
                      required: !isOptional,
                      minLength: 1,
                    }),
                  };
                  return (
                    <Input
                      id={labelId}
                      isInvalid={isInvalid}
                      disabled={disabled}
                      {...options}
                      onBlur={(e) => {
                        e.target.value !== item?.nameIt && setIsDirty(true);
                        return options.onBlur?.(e);
                      }}
                    />
                  );
                }}
              </FormField>
              <FormField
                label={i18n.translation.common.name}
                error={errors.nameDe}
                hidden={controller.selectedIndex != 2}
                isOptional
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("nameDe", {
                      required: !isOptional,
                      minLength: 1,
                    }),
                  };
                  return (
                    <Input
                      id={labelId}
                      isInvalid={isInvalid}
                      disabled={disabled}
                      {...options}
                      onBlur={(e) => {
                        e.target.value !== item?.nameDe && setIsDirty(true);
                        return options.onBlur?.(e);
                      }}
                    />
                  );
                }}
              </FormField>
              <FormField
                label={i18n.translation.common.name}
                error={errors.nameEs}
                hidden={controller.selectedIndex != 3}
                isOptional
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("nameEs", {
                      required: !isOptional,
                      minLength: 1,
                    }),
                  };
                  return (
                    <Input
                      id={labelId}
                      isInvalid={isInvalid}
                      disabled={disabled}
                      {...options}
                      onBlur={(e) => {
                        e.target.value !== item?.nameEs && setIsDirty(true);
                        return options.onBlur?.(e);
                      }}
                    />
                  );
                }}
              </FormField>
              <FormField
                label={i18n.translation.common.name}
                error={errors.nameFr}
                hidden={controller.selectedIndex != 4}
                isOptional
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("nameFr", {
                      required: !isOptional,
                      minLength: 1,
                    }),
                  };
                  return (
                    <Input
                      id={labelId}
                      isInvalid={isInvalid}
                      disabled={disabled}
                      {...options}
                      onBlur={(e) => {
                        e.target.value !== item?.nameFr && setIsDirty(true);
                        return options.onBlur?.(e);
                      }}
                    />
                  );
                }}
              </FormField>
              <FormField
                label={i18n.translation.common.description}
                error={!automaticSync ? errors.description : undefined}
                hidden={controller.selectedIndex != 0}
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("description", {
                      required: !isOptional && !automaticSync,
                      minLength: 1,
                    }),
                  };
                  return (
                    <Textarea
                      id={labelId}
                      isInvalid={isInvalid}
                      disabled={disabled || automaticSync}
                      {...options}
                      onBlur={(e) => {
                        e.target.value !== item?.description && setIsDirty(true);
                        return options.onBlur?.(e);
                      }}
                    />
                  );
                }}
              </FormField>
              <FormField
                label={i18n.translation.common.description}
                error={errors.descriptionIt}
                hidden={controller.selectedIndex != 1}
                isOptional
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("descriptionIt", {
                      required: !isOptional && !automaticSync,
                      minLength: 1,
                    }),
                  };
                  return (
                    <Textarea
                      id={labelId}
                      isInvalid={isInvalid}
                      disabled={disabled || automaticSync}
                      {...options}
                      onBlur={(e) => {
                        e.target.value !== item?.descriptionIt && setIsDirty(true);
                        return options.onBlur?.(e);
                      }}
                    />
                  );
                }}
              </FormField>
              <FormField
                label={i18n.translation.common.description}
                error={errors.descriptionDe}
                hidden={controller.selectedIndex != 2}
                isOptional
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("descriptionDe", {
                      required: !isOptional && !automaticSync,
                      minLength: 1,
                    }),
                  };
                  return (
                    <Textarea
                      id={labelId}
                      isInvalid={isInvalid}
                      disabled={disabled || automaticSync}
                      {...options}
                      onBlur={(e) => {
                        e.target.value !== item?.descriptionDe && setIsDirty(true);
                        return options.onBlur?.(e);
                      }}
                    />
                  );
                }}
              </FormField>
              <FormField
                label={i18n.translation.common.description}
                error={errors.descriptionEs}
                hidden={controller.selectedIndex != 3}
                isOptional
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("descriptionEs", {
                      required: !isOptional && !automaticSync,
                      minLength: 1,
                    }),
                  };
                  return (
                    <Textarea
                      id={labelId}
                      isInvalid={isInvalid}
                      disabled={disabled || automaticSync}
                      {...options}
                      onBlur={(e) => {
                        e.target.value !== item?.descriptionEs && setIsDirty(true);
                        return options.onBlur?.(e);
                      }}
                    />
                  );
                }}
              </FormField>
              <FormField
                label={i18n.translation.common.description}
                error={errors.descriptionFr}
                hidden={controller.selectedIndex != 4}
                isOptional
              >
                {({ labelId, isOptional, isInvalid }) => {
                  const options = {
                    ...register("descriptionFr", {
                      required: !isOptional && !automaticSync,
                      minLength: 1,
                    }),
                  };
                  return (
                    <Textarea
                      id={labelId}
                      isInvalid={isInvalid}
                      disabled={disabled || automaticSync}
                      {...options}
                      onBlur={(e) => {
                        e.target.value !== item?.descriptionFr && setIsDirty(true);
                        return options.onBlur?.(e);
                      }}
                    />
                  );
                }}
              </FormField>
              <Controller
                name="minConfigurableQuantity"
                control={control}
                render={({ field }) => (
                  <FormField
                    label={i18n.translation.common.minConfigurableQuantity}
                    error={errors.minConfigurableQuantity}
                  >
                    {({ labelId, isInvalid }) => (
                      <Numeric
                        {...field}
                        id={labelId}
                        isInvalid={isInvalid}
                        disabled={disabled || controller.selectedIndex != 0}
                        decimalScale={2}
                        allowNegative={false}
                        onBlur={(value) => setValue("minConfigurableQuantity", value ?? null)}
                      />
                    )}
                  </FormField>
                )}
              />
              <FormField label={i18n.translation.common.minimumOrderQuantity}>
                <Input disabled defaultValue={item?.minOrderQuantity} />
              </FormField>
              <FormField label={i18n.translation.common.automaticSync}>
                {({ labelId }) => (
                  <Switch
                    id={labelId}
                    checked={automaticSync}
                    disabled={disabled || controller.selectedIndex != 0}
                    onChange={(checked) => setValue("automaticSync", checked)}
                  />
                )}
              </FormField>
              <FormField label={i18n.translation.common.lastSync}>
                <Input
                  disabled
                  value={
                    item?.lastSync
                      ? formatTimeStamp(item.lastSync, auth.identity?.language)
                      : i18n.translation.common.never
                  }
                />
              </FormField>
            </FormFieldsContainer>
          );
        }}
      </Form>
    </ScrollContent>
  );

  return <Tabs controller={controller} tabs={tabs} panels={languages.map(() => panel)} />;
};
