import { FC, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useI18n from "../../hooks/useTranslations";
import { CustomError, isNotFound } from "../../http/errors";
import { Configuration as Conf } from "../../http/types/configurations";
import { useConfigurationsApi } from "../../http/useConfigurations";
import ErrorText from "../ui/ErrorText";
import Header, { HeaderPath } from "../ui/Header";
import ScrollContent from "../ui/ScrollContent";
import Guard from "./guard/Guard";
import HRail from "./hrail/HRail";
import Ladstep from "./ladstep/Ladstep";
import Overpass from "./overpass/Overpass";
import Patrol from "./patrol/Patrol";
import SafetyNet from "./safetyNet/SafetyNet";
import { isGuard, isHRail, isLadstep, isOverpass, isPatrol, isSafetyNet, isVertigrip } from "./typeGurads";
import Vertigrip from "./vertigrip/Vertigrip";

interface ConfigurationProps {
  isAdmin?: boolean;
}

export interface UpdateConfigurationFormBody {
  name: string;
  description?: string;
}

const Configuration: FC<ConfigurationProps> = ({ isAdmin }: ConfigurationProps) => {
  const i18n = useI18n();
  const { projectId } = useParams<{ projectId: string }>();
  const { id } = useParams<{ id: string }>();
  const configurationsApi = useConfigurationsApi();

  const aborter = useRef(new AbortController());
  const { error, data } = configurationsApi.getConfiguration(id!, aborter.current.signal);

  // Store variables in order to avoid the UI to rerender when mutating
  const [state, setState] = useState<{ data?: Conf; error?: CustomError }>();

  useEffect(() => {
    setState({
      data: data ?? state?.data,
      error: error ?? state?.error,
    });
  }, [data, error]);

  const path: HeaderPath[] = projectId
    ? [
        {
          url: `${isAdmin ? "/admin" : ""}/projects`,
          display: i18n.translation.projects.my,
        },
        {
          url: `${isAdmin ? "/admin" : ""}/projects/${state?.data?.project.id}?tab=1`,
          display: `${state?.data?.project.name ?? ""}: ${i18n.translation.configurations.plural.toLowerCase()}`,
        },
      ]
    : [
        {
          url: `${isAdmin ? "/admin" : ""}/configurations`,
          display: isAdmin ? i18n.translation.configurations.all : i18n.translation.configurations.my,
        },
      ];

  path.push({
    display: state?.data?.name ?? "",
  });

  if (!state?.data && !state?.error) {
    return;
  }

  const props = { configuration: state.data!, isAdmin: isAdmin };

  return !state?.error ? (
    <>
      <Header title={state.data?.system?.name ?? i18n.translation.common.loading} path={path} />
      {isVertigrip(state.data) ? (
        <Vertigrip {...props}></Vertigrip>
      ) : isGuard(state.data) ? (
        <Guard {...props}></Guard>
      ) : isPatrol(state.data) ? (
        <Patrol {...props}></Patrol>
      ) : isHRail(state.data) ? (
        <HRail {...props}></HRail>
      ) : isSafetyNet(state.data) ? (
        <SafetyNet {...props}></SafetyNet>
      ) : isLadstep(state.data) ? (
        <Ladstep {...props}></Ladstep>
      ) : isOverpass(state.data) ? (
        <Overpass {...props}></Overpass>
      ) : undefined}
    </>
  ) : (
    <>
      <Header
        title={isNotFound(error) ? i18n.translation.common.notFound : i18n.translation.common.error}
        error={error}
      />
      <ScrollContent padding>
        <ErrorText
          text={isNotFound(error) ? i18n.translation.common.resourceNotFound : i18n.translation.common.genericError}
        />
      </ScrollContent>
    </>
  );
};

export default Configuration;
