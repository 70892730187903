import classNames from "classnames";

import { FC, useContext, useEffect, useState } from "react";
import NotificationDispatch, { hideNotification } from "../../context/notificationContext";
import SvgClose from "../icons/Close";
import SvgError from "../icons/Error";
import SvgInfo from "../icons/Info";
import SvgTaskAlt from "../icons/TaskAlt";
import Icon from "./Icon";
import styles from "./Notification.module.css";

interface Props {
  type: "success" | "error" | "informative" | "warning";
  text: string;
  delayed?: number;
}

const Notification: FC<Props> = ({ type, text, delayed = 3000 }: Props) => {
  const [mounted, setMounted] = useState(false);
  const [close, setClose] = useState(false);
  const dispatch = useContext(NotificationDispatch);

  useEffect(() => {
    const timeout = setTimeout(() => setMounted(true), 0);
    return () => clearTimeout(timeout);
  });

  useEffect(() => {
    const timeout = setTimeout(() => setClose(true), delayed);
    return () => clearTimeout(timeout);
  });

  return (
    <div
      className={classNames(styles.notification, {
        [styles.success]: type === "success",
        [styles.error]: type === "error",
        [styles.informative]: type === "informative",
        [styles.warning]: type === "warning",
        [styles.shown]: mounted,
        [styles.hidden]: close,
      })}
      onTransitionEnd={() => {
        if (close) dispatch(hideNotification());
      }}
    >
      <Icon
        className={styles.icon}
        glyph={{ error: SvgError, success: SvgTaskAlt, informative: SvgInfo, warning: SvgInfo }[type]}
      />
      <div className={styles.text}>{text}</div>
      <button className={styles.closeButton} onClick={() => setClose(true)}>
        <Icon className={styles.closeIcon} glyph={SvgClose} />
      </button>
    </div>
  );
};

export default Notification;
