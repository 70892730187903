import { FC, useRef } from "react";
import { useParams } from "react-router-dom";
import useI18n from "../../../hooks/useTranslations";
import { useSystemsApi } from "../../../http/useSystems";
import ErrorText from "../../ui/ErrorText";
import Header from "../../ui/Header";
import ScrollContent from "../../ui/ScrollContent";
import GuardSettings from "./guard/GuardSettings";
import HRailSettings from "./hrail/HRailSettings";
import LadstepSettings from "./ladstep/LadstepSettings";
import OverpassSettings from "./overpass/OverpassSettings";
import PatrolSettings from "./patrol/PatrolSettings";
import SafetyNetSettings from "./safetyNet/SafetyNetSettings";
import VertigripSettings from "./vertigrip/VertigripSettings";

const AdminSettings: FC = () => {
  const i18n = useI18n();
  const { id } = useParams<{ id: string }>();
  const systemApi = useSystemsApi();

  const aborter = useRef(new AbortController());
  const { error, data } = systemApi.getAdminSystem(id!, aborter.current.signal);

  if (!data && !error) {
    return;
  }

  const path = [
    { url: `/admin/systems/${id}`, display: data?.name ?? i18n.translation.systems.singular },
    { display: i18n.translation.common.settings },
  ];

  return (
    <>
      <Header title={data?.name ? i18n.translation.common.settingsSystem(data.name) : ""} error={error} path={path} />
      {data?.type == "vertigrip" ? (
        <VertigripSettings></VertigripSettings>
      ) : data?.type == "guard" ? (
        <GuardSettings></GuardSettings>
      ) : data?.type == "patrol" ? (
        <PatrolSettings></PatrolSettings>
      ) : data?.type == "h-rail" ? (
        <HRailSettings></HRailSettings>
      ) : data?.type == "safety-net" ? (
        <SafetyNetSettings></SafetyNetSettings>
      ) : data?.type == "ladstep" ? (
        <LadstepSettings></LadstepSettings>
      ) : data?.type == "overpass" ? (
        <OverpassSettings></OverpassSettings>
      ) : (
        <ScrollContent padding>
          <ErrorText text={i18n.translation.common.genericError} />
        </ScrollContent>
      )}
    </>
  );
};

export default AdminSettings;
