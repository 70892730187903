import { useCallback } from "react";
import useSWRImmutable from "swr/immutable";
import { Paginated, PaginationQuery } from "../types";
import { buildUrl, mutateCache, useApi } from "./api";
import { CustomError } from "./errors";
import { SearchAdminDocument, SearchDocument } from "./types/documents";

export const useDocumentsApi = () => {
  const api = useApi();

  const getProjectDocuments = useCallback(
    (projectId: string, query: PaginationQuery, signal: AbortSignal) => {
      const url = buildUrl(`/projects/${projectId}/documents`, query);
      return useSWRImmutable<Paginated<SearchDocument>, CustomError, string>(url, (url) =>
        api.get<Paginated<SearchDocument>>({ url, signal })
      );
    },
    [api]
  );

  const getAdminProjectDocuments = useCallback(
    (query: PaginationQuery, signal: AbortSignal) => {
      const url = buildUrl(`/admin/project_documents`, query);
      return useSWRImmutable<Paginated<SearchAdminDocument>, CustomError, string>(url, (url) =>
        api.get<Paginated<SearchAdminDocument>>({ url, signal })
      );
    },
    [api]
  );

  const insertProjectDocument = useCallback(
    async (projectId: string, file: File, overwrite?: boolean): Promise<void> => {
      const formData = new FormData();
      formData.append("file", file);

      await api.put<FormData>(`/projects/${projectId}/documents${overwrite ? "?overwrite=1" : ""}`, formData);
      mutateCache([
        "/projects",
        "/project_documents",
        "/admin/projects",
        "/admin/project_documents",
        `/projects/${projectId}/documents`,
      ]);
    },
    [api]
  );

  const deleteProjectDocument = useCallback(
    async (projectId: string, documentId: string): Promise<void> => {
      await api.del(`/project_documents/${documentId}`).then(() => {
        mutateCache([
          "/projects",
          `/projects/${projectId}/documents`,
          "/project_documents",
          `/project_documents/${documentId}`,
          "/admin/projects",
          "/admin/project_documents",
        ]);
      });
    },
    [api]
  );

  return {
    getProjectDocuments,
    getAdminProjectDocuments,
    insertProjectDocument,
    deleteProjectDocument,
  };
};
