import { FC, Fragment, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import useInitializer from "../../hooks/useInitializer";
import useI18n from "../../hooks/useTranslations";
import { useSystemsApi } from "../../http/useSystems";
import BorderBox from "../ui/BorderBox";
import Header from "../ui/Header";
import ScrollContent from "../ui/ScrollContent";
import styles from "./Home.module.css";

const Home: FC = () => {
  const i18n = useI18n();
  const navigate = useNavigate();
  const aborter = useRef(new AbortController());
  const systemApi = useSystemsApi();

  const { data, error, trigger } = systemApi.getSystems(aborter.current.signal);

  useEffect(() => {
    trigger();
  }, []);

  const isInitialized = useInitializer(data || error);

  if (!data && !error && !isInitialized) {
    return;
  }

  return (
    <>
      <Header title={i18n.translation.systems.our} path={{ display: i18n.translation.common.home }} error={error} />
      <ScrollContent padding>
        <div className={styles.grid}>
          {data?.map((x) => (
            <Fragment key={x.id}>
              <BorderBox
                onClick={() => navigate(`/systems/${x.id}`)}
                showIsMissing
                title={x.name}
                description={x.descriptionShort}
              >
                <div className={styles.img}>
                  {x.image ? (
                    <BorderBox.Image src={import.meta.env.VITE_BUCKET_URL + x.image} />
                  ) : (
                    BorderBox.PlaceHolder
                  )}
                </div>
              </BorderBox>
            </Fragment>
          ))}
        </div>
      </ScrollContent>
    </>
  );
};

export default Home;
