import { Glyph } from "../../types";

const SvgTextSnippet: Glyph = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 -960 960 960" {...props}>
      <path d="M216-216h528v-354L570-744H216v528Zm0 72q-29.7 0-50.85-21.15Q144-186.3 144-216v-528q0-29.7 21.15-50.85Q186.3-816 216-816h384l216 216v384q0 29.7-21.15 50.85Q773.7-144 744-144H216Zm72-144h384v-72H288v72Zm0-144h384v-72H288v72Zm0-144h288v-72H288v72Zm-72 360v-528 528Z" />
    </svg>
  );
};

export default SvgTextSnippet;
