import classNames from "classnames";
import { FC, PropsWithChildren, useEffect } from "react";
import styles from "./Sidebar.module.css";

type SideBarProps = PropsWithChildren<{
  isOpen: boolean;
  close: () => void;
}>;

export const SideBar: FC<SideBarProps> = ({ isOpen, close, children }: SideBarProps) => {
  useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "Escape") close();
    };
    isOpen ? window.addEventListener("keydown", onKeyDown) : window.removeEventListener("keydown", onKeyDown);
    return () => window.removeEventListener("keydown", onKeyDown);
  }, [isOpen]);

  return (
    <aside
      className={classNames(styles.sidebar, {
        [styles.sidebarClosed]: !isOpen,
      })}
    >
      <div className={styles.sidebarInner}>{children}</div>
      {isOpen && <div className={styles.menuBackground} onClick={() => close()} />}
    </aside>
  );
};

export default SideBar;
