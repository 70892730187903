import { Glyph } from "../../types";

const SvgLogo: Glyph = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 124.44 31.17" {...props}>
      <g>
        <path d="M53.93,15.72l-7.6-3.95c-.2-.12-.32-.25-.32-.43,0-.37.37-.69.89-.69h10.09c.23,0,.41-.12.52-.36l.41-.91c.02-.07.04-.14.04-.21,0-.11-.07-.16-.23-.16h-15.13c-.41,0-.79.22-.99.59l-1.79,3.26c-.23.42-.07.95.36,1.18l8.34,4.36c.21.11.32.23.32.41,0,.39-.36.69-.89.69h-10.44c-.25,0-.43.12-.53.39l-.41.91c-.04.05-.05.12-.05.16,0,.12.09.2.27.2h14.5c.29,0,.56-.15.71-.41l2.29-3.87c.24-.41.09-.93-.33-1.15Z" />
        <path d="M72.95,9.32c-.11-.2-.25-.3-.53-.3h-.87c-.27,0-.52.11-.8.3l-15.85,11.3c-.18.12-.27.25-.27.36s.07.18.21.18h12.62c.24,0,.45-.14.55-.36l.41-.91c.04-.09.05-.14.05-.2,0-.12-.11-.2-.27-.2h-8.26c-.47,0-.66-.6-.28-.87l8.4-6.05,4,8.27c.09.21.29.3.61.3h5.55c.28,0,.44-.14.44-.32,0-.04,0-.12-.02-.18l-5.69-11.33Z" />
        <path d="M101.79,13.69h-11.55l1.07-2.41c.17-.38.55-.63.97-.63h10.96c.23,0,.44-.14.53-.35l.39-.91c.04-.07.05-.14.05-.21,0-.11-.09-.16-.25-.16h-16.85c-.42,0-.8.25-.97.63l-1.96,4.41-3.01,6.74c-.02.07-.04.12-.04.18,0,.11.07.18.25.18h5.16c.25,0,.43-.12.53-.36l2.42-5.44h11.55c.25,0,.41-.09.48-.25l.52-1.14s.04-.07.04-.11c0-.09-.12-.16-.3-.16Z" />
        <path d="M124.19,9.01h-15.83c-.42,0-.8.25-.97.63l-1.97,4.41-3.01,6.74c-.02.07-.04.12-.04.18,0,.11.07.18.25.18h16.2c.25,0,.43-.12.53-.36l.41-.91c.04-.09.05-.14.05-.2,0-.12-.09-.2-.27-.2h-10.66l1.84-4.15h10.49c.25,0,.41-.09.5-.25l.5-1.14s.04-.07.04-.11c0-.09-.11-.16-.3-.16h-10.49l1.07-2.41c.17-.38.55-.63.97-.63h9.94c.25,0,.43-.12.53-.36l.39-.91c.04-.07.05-.14.05-.21,0-.11-.07-.16-.25-.16Z" />
      </g>
      <path
        fill="#de253d"
        d="M3.19,22.88c.26.32.78.24.94-.13l6.73-15.88c.27-.62.88-1.02,1.56-1.02h5.5c.65,0,1.08.67.82,1.26l-1.71,3.88c-.13.29-.35.54-.63.69l-3.75,2.92c-.37.29-.16.88.3.88h7.24c.33,0,.56.34.42.65l-4.1,10.36H6.31c-.19,0-.3.22-.18.37l3.3,4.02c.15.18.37.29.61.29h6.63c.27,0,.53-.07.76-.2l4.72-2.58c.12-.06.21-.17.25-.29l4.34-12.01c.26-.59-.17-1.26-.82-1.26h-7.17c-.29,0-.41-.39-.16-.55l3.98-2.58,2.4-5.47c.11-.24.1-.52-.02-.76l-2.45-4.98c-.15-.31-.46-.5-.81-.5h-7.23c-.36,0-.68.21-.82.54l-1.9,4.3c-.27.62-.88,1.01-1.56,1.01h-4.15c-.36,0-.68.21-.82.54L.04,18.58c-.08.19-.05.41.08.56l3.07,3.73Z"
      />
    </svg>
  );
};

export default SvgLogo;
