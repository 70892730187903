import { FC, useContext, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import NotificationDispatch, { showErrorNotification } from "../../context/notificationContext";
import { useDebounce } from "../../hooks/useDebounce";
import useInitializer from "../../hooks/useInitializer";
import useListFilters from "../../hooks/useListFilters";
import useI18n from "../../hooks/useTranslations";
import { ProjectSharing, UpdateProjectSharingBody } from "../../http/types/projectSharings";
import { useProjectsApi } from "../../http/useProjects";
import { useProjectSharingsApi } from "../../http/useProjectSharings";
import { PaginationQuery } from "../../types";
import { formatBoolean } from "../../utils";
import Button from "../ui/Button";
import Form from "../ui/Form";
import FormField from "../ui/FormField";
import Header from "../ui/Header";
import ModalDialog from "../ui/ModalDialog";
import ScrollContent from "../ui/ScrollContent";
import Switch from "../ui/Switch";
import Table from "../ui/Table";
import ShareProjectButton from "./ShareProjectButton";

interface ShareProjectForm {
  edit: boolean;
}

interface ProjectSharingsProps {
  isAdmin?: boolean;
}

const ProjectSharings: FC<ProjectSharingsProps> = ({ isAdmin }: ProjectSharingsProps) => {
  const i18n = useI18n();
  const projectsApi = useProjectsApi();
  const projectSharingsApi = useProjectSharingsApi();

  const { id: projectId } = useParams<{ id: string }>();
  const dispatch = useContext(NotificationDispatch);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const filters = useListFilters({ isSearchable: true });
  const aborter = useRef(new AbortController());
  const [sharingToEdit, setSharingToEdit] = useState<ProjectSharing>();
  const [sharingToDelete, setSharingToDelete] = useState<ProjectSharing>();

  const debouncedValue = useDebounce(filters.state.q);

  const query: PaginationQuery = {
    ...filters.state,
    q: debouncedValue,
  };

  const { data, error } = projectSharingsApi.getProjectSharings(projectId!, query, aborter.current.signal);
  const { data: project } = projectsApi.getProject(projectId!, aborter.current.signal);

  const onSubmit = (formData: ShareProjectForm) => {
    setIsSubmitting(true);
    const body: UpdateProjectSharingBody = {
      edit: formData.edit,
    };
    sharingToEdit &&
      projectSharingsApi
        .updateProjectSharing(sharingToEdit.project.id, sharingToEdit.user.id, body)
        .then(() => {
          setSharingToEdit(undefined);
          setIsSubmitting(false);
        })
        .catch((err) => {
          dispatch(showErrorNotification(i18n, err));
          setIsSubmitting(false);
        });
  };

  const isInitialized = useInitializer(data || error);

  if (!data && !error && !isInitialized) {
    return;
  }

  return (
    <>
      <Header
        title={i18n.translation.projects.sharings}
        error={error}
        path={[
          {
            display: isAdmin ? i18n.translation.projects.all : i18n.translation.projects.my,
            url: `${isAdmin ? "/admin" : ""}/projects`,
          },
          {
            display: project?.name ?? "Project",
            url: `${isAdmin ? "/admin" : ""}/projects/${projectId}`,
          },
          { display: i18n.translation.projects.sharings },
        ]}
      />
      <ScrollContent padding paddingTop>
        <Table<ProjectSharing>
          filters={filters}
          isTall={true}
          searchOptions={{
            placeholder: i18n.translation.users.search,
            onChange: () => {
              aborter.current.abort();
              aborter.current = new AbortController();
            },
          }}
          searchButtons={<ShareProjectButton projectId={projectId!}></ShareProjectButton>}
          head={
            <tr>
              <th align="left">{i18n.translation.users.usersName}</th>
              <th align="left">{i18n.translation.users.usersEmail}</th>
              <th align="left">{i18n.translation.projects.permissionToEdit}</th>
              <th></th>
            </tr>
          }
          items={data?.items}
          renderRow={(item) => (
            <>
              <td>{item.user?.name}</td>
              <td>{item.user?.email}</td>
              <td>{formatBoolean(i18n.translation, item.edit)}</td>
            </>
          )}
          rowActions={[
            { title: i18n.translation.projects.editPermissions, onClick: (item) => setSharingToEdit(item) },
            { title: i18n.translation.common.remove, onClick: (item) => setSharingToDelete(item) },
          ]}
          total={data?.total}
          error={!!error}
        />
        <ModalDialog
          isOpen={!!sharingToDelete}
          onClose={() => setSharingToDelete(undefined)}
          title={i18n.translation.users.remove}
          actions={[
            {
              title: i18n.translation.common.cancel,
              onClick: () => setSharingToDelete(undefined),
            },
            {
              title: i18n.translation.common.yes,
              onClick: () => {
                sharingToDelete &&
                  projectSharingsApi
                    .deleteProjectSharing(projectId!, sharingToDelete.user.id)
                    .then(() => setSharingToDelete(undefined))
                    .catch((err) => dispatch(showErrorNotification(i18n, err)));
              },
            },
          ]}
        >
          {i18n.translation.projects.questions.unshare}
        </ModalDialog>
        <ModalDialog
          isOpen={!!sharingToEdit}
          onClose={() => setSharingToEdit(undefined)}
          title={i18n.translation.projects.editPermissions}
        >
          <Form<ShareProjectForm>
            submitText={i18n.translation.common.save}
            disabled={isSubmitting}
            onSubmit={() => onSubmit}
            defaultValues={{ edit: sharingToEdit?.edit }}
            secondaryButton={
              <Button buttonProps={{ onClick: () => setSharingToEdit(undefined) }}>
                {i18n.translation.common.cancel}
              </Button>
            }
          >
            {({ setValue, watch }) => {
              const edit = watch("edit");

              return (
                <FormField label={"Write permission"}>
                  {({ labelId }) => (
                    <Switch id={labelId} checked={edit} onChange={(checked) => setValue("edit", checked)} />
                  )}
                </FormField>
              );
            }}
          </Form>
        </ModalDialog>
      </ScrollContent>
    </>
  );
};

export default ProjectSharings;
